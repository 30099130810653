import { takeEvery, all, call, put } from 'redux-saga/effects'
import moment from 'moment'
import Api from 'services/api/Api'
import getEndPoints from '../endpoints'
import actions from './actions'

const api = new Api()

export function* SUBMIT_IO_INVITATION_REDUCERS({
  resolve,
  reject,
  formValues
}) {
  yield put({
    type: actions.SUBMIT_IO_INVITATION_SUCCESS,
    payload: {
      formType: formValues.contactWish,
      fund: formValues.fund,
      accountType: formValues.investmentType,
      expectedContribution: formValues.expectedContribution,
      isExistingInvestor: formValues.investorName.isExistingInvestor,
      firstName: formValues.investorName.firstName,
      middleInitial: formValues.investorName.middleInitial,
      lastName: formValues.investorName.lastName,
      emailAddress: formValues.contactInformation.email,
      phoneNumber: formValues.contactInformation.phoneNumber,
      sms: formValues.contactInformation.isMobile,
      addressLine1: formValues.contactInformation.streetOne,
      addressLine2: formValues.contactInformation.streetTwo,
      zipCode: formValues.contactInformation.zip,
      state: formValues.contactInformation.investorState,
      city: formValues.contactInformation.city,
      country: formValues.contactInformation.country
    },
  })

  try {
    yield call(resolve, "success")
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SUBMIT_IO_INVITATION({
  resolve,
  reject,
  investoronboarding,
  userId,
  entityId
}) {
  const { isExistingInvestor } = investoronboarding

  const personId = 1
  const entityContactAddressesId = 1
  const contactAddressId = 1
  const entityContactPhonesId = 1
  const entityContactEmailsId = 1

  const users = {
    id: userId,
    emailAddress: investoronboarding.emailAddress,
    userStatusType: '/api/user_status_types/active',
    person: {
      '@id': `/api/people/${personId}`,
      firstName: investoronboarding.firstName,
      middleName: investoronboarding.middleInitial ? investoronboarding.middleInitial : null,
      lastName: investoronboarding.lastName,
      entity: {
        '@id': `/api/entities/${entityId}`,
        entityType: `/api/entity_types/${investoronboarding.accountType}`,
        entityContactAddresses: [
          { '@id': `/api/entity_contact_addresses/${entityContactAddressesId}` },
        ],
        entityContactPhones: [
          {
            '@id': `/api/entity_contact_phones/${entityContactPhonesId}`,
            phoneNumber: investoronboarding.phoneNumber,
            isSms: investoronboarding.isSms === 'yes',
            phoneNumberOption: '1',
            phoneNumberFormat: 'hello',
          },
        ],
        entityContactEmails: [
          {
            '@id': `/api/entity_contact_emails/${entityContactEmailsId}`,
            emailAddress: investoronboarding.emailAddress,
          },
        ],
      },
    },
  }

  const contactAddresses = {
      id: contactAddressId,
      address1: investoronboarding.addressLine1,
      address2: investoronboarding.addressLine2 ? investoronboarding.addressLine2 : "",
      city: investoronboarding.city,
      state: investoronboarding.state && `/api/geo_states/${investoronboarding.state}`,
      country: investoronboarding.country && `/api/geo_countries/${investoronboarding.country}`,
      postalCode: investoronboarding.zipCode,
  }

  let entityFund = investoronboarding.fund

  if (users.person.middleName === null){
    users.person.entity.name = users.person.firstName.concat(" ", users.person.lastName)
  } else {
    users.person.entity.name = users.person.firstName.concat(" ", users.person.middleName, ". ", users.person.lastName)
  }

  try {
    let method
    let contactEndPoint
    let userEndPoint
    let entityMembershipEndPoint

    if (isExistingInvestor === 'yes') {
      method = api.put

      const { id: userID } = users
      const { id: contactAddressID } = contactAddresses
      const { id: entityMembershipsID } = entityFund
      delete users.id
      delete contactAddresses.id
      delete entityFund.id
      contactEndPoint = getEndPoints('contactAddresses', contactAddressID)
      userEndPoint = getEndPoints('user', userID)
      entityMembershipEndPoint = getEndPoints('entityMembership', entityMembershipsID)
    } else {
      method = api.post

      delete users.id
      delete contactAddresses.id
      delete users.person['@id']
      delete users.person.entity['@id']
      delete users.person.entity.entityContactAddresses[0]['@id']
      delete users.person.entity.entityContactPhones[0]['@id']
      delete users.person.entity.entityContactEmails[0]['@id']

      contactEndPoint = getEndPoints('contactAddresses')
      userEndPoint = getEndPoints('user')
      entityMembershipEndPoint = getEndPoints('entityMembership')
    }

    if(contactAddresses.state === ""){
      delete contactAddresses.state
    }
    // create/update address
    const contactAddressResponse = yield call(method, contactEndPoint, contactAddresses)

    users.person.entity.entityContactAddresses = [
      {
        ...users.person.entity.entityContactAddresses[0],
        contactAddress: `/api/contact_addresses/${contactAddressResponse.data.id}`,
      },
    ]

      // create/update user
    const userResponse = yield call(method, userEndPoint, users)

    yield call(resolve, userResponse)

    const {
      data: {
        person: {
          id: userID,
          entity: { id: entityID },
        },
      },
    } = userResponse

    if (isExistingInvestor === 'no') {
      const authData = {
        name: users.person.entity.name ,
        email: investoronboarding.emailAddress,
        role: 'rol_J3uV3jqep8p7xSq4',
        status: 'invited'
      }

      // create/update auth0
      const authResponse = yield call(api.post, '/a0u/create', authData)

      const commData = {
        sender: "support@s2n.solutions",
        user: `/api/users/${userID}`,
        dateSent: moment().format("YYYY-MM-DDThh:mm:ss"),
        expiredDate: moment().add(336 , 'h').format("YYYY-MM-DDThh:mm:ss"),
        communicationType: '/api/communication_types/software-invitation',
        template: '/api/templates/d-d7af63628b614b1c88bd9813e70fc198',
        var: {
          investorName: users.person.entity.name,
          newAccountLink: authResponse.data[0].changePasswordURL
        }
      }

      // send sendgrid email
     yield call(api.post, '/communications', commData)

      const userData = {
        sub: authResponse.data[0].id
      }

      // connect auth0 to user account
      yield call(api.put, `/users/${userID}`, userData )
    }

   entityFund = {
     entityMembershipType: 'api/entity_membership_types/investor_invited',
     entityFund:`/api/entity_funds/${entityFund.id}`,
     entity: `/api/entities/${entityFund.entity.id}`,
     memberEntity: `/api/entities/${entityID}`,
    }

    const entityMembershipResponse = yield call(method, entityMembershipEndPoint, entityFund)

    const investorAccountData = {
      investorEntity: `/api/entities/${entityID}`,
      balance: parseFloat(investoronboarding.expectedContribution, 10)
    }

    yield call(api.post, '/investor_accounts', investorAccountData)

    yield put({
      type: actions.SUBMIT_IO_INVITATION_SUCCESS,
      payload: {
        entityId: userResponse.data.person.entity.id,
        userId: userResponse.data.id,
        emailAddress: userResponse.data.emailAddress,
        entityMembershipId: entityMembershipResponse.data.id,
        isExistingInvestor: isExistingInvestor === 'yes',
      },
    })
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_FUND_AND_ACCOUNT_TYPES({ resolve, reject }) {
  try {
    const fundsResponse = yield call(api.get, getEndPoints('entityFunds'))
    const accountTypesResponse = yield call(api.get, getEndPoints('entityTypes'))

    yield call(resolve, {
      fundsData: fundsResponse.data,
      accountTypesData: accountTypesResponse.data,
    })
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SEARCH_INVESTOR({ resolve, reject, email }) {
  try {
    const response = yield call(api.get, getEndPoints(['searchUser', email]))
    yield call(resolve, response.data)
  } catch (error) {
    yield call(reject, 'error')
  }
}

export function* FETCH_INVESTOR({ resolve, reject, userId }) {
  try {
    const userResponse = yield call(api.get, getEndPoints('users', userId))
    const {
      person: { entityId },
    } = userResponse.data

    const contactResponse = yield call(api.get, getEndPoints('entities', entityId))

    const userData = userResponse.data
    const contactData = contactResponse.data

    yield call(resolve, { userData, contactData })
  } catch (error) {
    yield call(reject, 'error')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SUBMIT_IO_INVITATION, SUBMIT_IO_INVITATION),
    takeEvery(actions.SUBMIT_IO_INVITATION_REDUCERS, SUBMIT_IO_INVITATION_REDUCERS),
    takeEvery(actions.FETCH_FUND_AND_ACCOUNT_TYPES, FETCH_FUND_AND_ACCOUNT_TYPES),
    takeEvery(actions.SEARCH_INVESTOR, SEARCH_INVESTOR),
    takeEvery(actions.FETCH_INVESTOR, FETCH_INVESTOR),
  ])
}
