export default {
  emailValidator: (value) => {
    if (!value) {
      return 'Please enter your e-mail address'
    }
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value)) {
      return 'Invalid email address'
    }
    return undefined
  },
  loginPasswordValidator: (value) => {
    if (!value) {
      return 'Please enter your password'
    }

    return undefined
  },
  passwordValidator: (value) => {
    if (!value) {
      return 'Please enter your password'
    }
    if (value) {
      const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      const password = regex.test(value)
      if (!password) {
        return 'Password must be 8 characters or longer and must contain at least one number, one uppercase letter and special character'
      }
    }
    return undefined
  },
  changePasswordValidator: (value) => {
    if (value) {
      const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      const password = regex.test(value)
      if (!password) {
        return 'Password must be 8 characters or longer and must contain at least one number, one uppercase letter and special character'
      }
    }
    return undefined
  },
  signupPasswordValidator: (value) => {
    if (!value) {
      return '    Please enter your password.                                   '
    }
    if (value) {
      const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      const password = regex.test(value)
      if (!password) {
        return 'Password must be 8 characters or longer and must contain at least one number, one uppercase letter and special character'
      }
    }
    return undefined
  },
  emptyValidator: (value) => (value ? undefined : 'This field is required'),
  nameValidator: (value) => (/^[a-zA-Z]+$/g.test(value) ? undefined : 'Enter valid name'),
  phoneValidator: (value) => {
    if (!value || value.length < 10) {
      return 'Enter valid phone number'
    }
    return undefined
  },
  amountValidator: (value) => {
    const regex = /^\$?[0-9]\d*(((,\d{3})+)?(\.\d{0,2})?)$/
    const isNumber = regex.test(value)
    if (!value || !isNumber) {
      return 'Enter valid amount'
    }
    return undefined
  },
  areaSizeValidator: (value) => (value ? undefined : 'Enter valid area size'),
  lengthValidator: (value, length) => (value && value.length < length ? 'Invalid length' : ''),
  FICORangeValidator: (value) => {
    const FICOValue = Number(value)

    if (!value) {
      return 'This field is required'
    }

    if (FICOValue > 850 || FICOValue < 300) {
      return 'The value must be between 300 - 850'
    }

    return undefined
  },
  validatePC: (value) => {
    if (!value) {
      return 'This field is required'
    }
    if (value && value.length < 5) {
      return 'Invalidate length'
    }

    if (value && value.length > 5) {
      const valildPC = value.match(/\d{5}-\d{4}/g)

      if (!valildPC) {
        return 'Invalid length'
      }
    }
    return undefined
  },
}
