import React, {Fragment} from 'react'

const FormPartContainer = ({ children }) => {
  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

export default FormPartContainer
