import moment from 'moment'

export default {
  formatRequiredLength: (value, length) => {
    const numbers = (value && value.replace(/[^.0-9]+/g, '')) || ''
    return numbers.slice(0, length)
  },
  normalizeMoney: value => {
    return value.replace(/[,|$]/g, '');
  },
  numbersOnly: value => value.replace(/[^.0-9]+/g, ''),
  formatMoney: (value) => {
    if (!value) {
      return value
    }
    value = value.toString()

    const importantChars = value.replace(/[^\d|^-]/g, '')
    const onlyNums = value.replace(/[^\d]/g, '')
    const inputLength = onlyNums.length

    if (inputLength <= 5) {
      return `${importantChars[0]==="-" ? "-$" : "$"}${onlyNums.slice(0, inputLength-2)}.${onlyNums.slice(inputLength-2, inputLength)}`
    }
    if (inputLength <= 8) {
      return `${importantChars[0]==="-" ? "-$" : "$"}${onlyNums.slice(0, inputLength-5)},${onlyNums.slice(inputLength-5, inputLength-2)}.${onlyNums.slice(inputLength-2, inputLength)}`
    }
    if (inputLength <= 11) {
      return `${importantChars[0]==="-" ? "-$" : "$"}${onlyNums.slice(0, inputLength-8)},${onlyNums.slice(inputLength-8, inputLength-5)},${onlyNums.slice(inputLength-5, inputLength-2)}.${onlyNums.slice(inputLength-2, inputLength)}`
    }
    if (inputLength <= 14) {
      return `${importantChars[0]==="-" ? "-$" : "$"}${onlyNums.slice(0, inputLength-11)},${onlyNums.slice(inputLength-11, inputLength-8)},${onlyNums.slice(inputLength-8, inputLength-5)},${onlyNums.slice(inputLength-5, inputLength-2)}.${onlyNums.slice(inputLength-2, inputLength)}`
    }
    return onlyNums
  },

  formatSSN: (value, style) => {
    if (!value) {
      return value
    }
    const stringifiedValue = value.toString()
    const onlyNums = stringifiedValue.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 5) {
      return `${onlyNums.slice(0, 3)}${style}${onlyNums.slice(3)}`
    }
    return `${onlyNums.slice(0, 3)}${style}${onlyNums.slice(3, 5)}${style}${onlyNums.slice(5, 9)}`
  },
  formatPhone: (value, style) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 6) {
      return `${onlyNums.slice(0, 3)}${style}${onlyNums.slice(3)}`
    }
    return `${onlyNums.slice(0, 3)}${style}${onlyNums.slice(3, 6)}${style}${onlyNums.slice(6, 10)}`
  },

  formatEIN: (value, style) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 2) {
      return onlyNums
    }
    return `${onlyNums.slice(0, 2)}${style}${onlyNums.slice(2, 9)}`
  },
  parseMoney: value => {
    if (!value) return value
    return value.replace(/[,]/g, '')
  },
  formatDate: value => {
    if (!value) return value
    if (moment.isMoment(value)) return value
    return moment(value.date)
  },
  parseDate: value => {
    if (!value) return value
    if (moment.isMoment(value))
      return {
        date: value.format(),
      }
    return value
  },
  parseSSN: value => {
    if (!value) return value
    return value.replace(/[-]/g, '')
  },
  formatPC: (value, style) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length > 5) {
      return `${onlyNums.slice(0, 5)}${style}${onlyNums.slice(5, 9)}`
    }

    return value
  },
}
