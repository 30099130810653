import React from 'react'
import { Field } from 'redux-form'

import InputFieldComponent from '../component/InputFieldComponent'

const StreetTwoField = ({ placeholder }) => (
  <Field name="streetTwo" placeholder={placeholder} component={InputFieldComponent} />
)

export default StreetTwoField
