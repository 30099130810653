import store from 'store'
import actions from './actions'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    logo: 'Prospera',
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: true,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left', // left, top, nomenu
    routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
    menuColor: 'white', // white, dark, gray
    theme: 'prospera', // light, dark
    // theme: 'default', // default, dark
    authPagesColor: 'white', // white, gray, image
    primaryColor: '#4b7cf3', // this should be fun
    leftMenuWidth: 320,  // this doesnt do jack
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false, // should be interesting
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false, // new
    isBorderless: false,
    logoUrlExpanded: '/resources/images/ideal-icon.png',
    logoUrlCollapsed: '/resources/images/favicon.png',
    isSettingsOpen: false,
    accountCompleted: true,
    isMenuTop: false,
    isRoundedCorners: true,
    isFixedWidth: false,
    selectionColor: '#7d9c6e',
    applicationColor: '#748e67',
    hoverColor: '#7d9c6e',
    backgroundOverlayTop: '',
    backgroundOverlayBottom: '',
    backgroundOpacity: 0,
    showCCModal: false,
    showBioModal: false,
  }),
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
