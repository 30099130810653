import React from 'react'
import { Field } from 'redux-form'

import InputFieldComponent from '../component/InputFieldComponent'

const MiddleInitialField = () => (
  <Field name="middleInitial" placeholder="MI" maxLength={2} component={InputFieldComponent} />
)

export default MiddleInitialField
