import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import user from './user/reducers'
import statements from './statements/reducers'
import qup from './qup/reducers'
import menu from './menu/reducers'
import settingsmenu from './settingsmenu/reducers'
import settings from './settings/reducers'
import entity from './entity/reducers'
import general from './general/reducers'
import investordashboard from './investordashboard/reducers'
import dropdownmenu from './dropdownmenu/reducer'
import team from './team/reducers'
import advisor from './advisor/reducers'
import userForm from './userForm/reducers'
import countdownTimerReducer from "./countdownTimer/reducers"
import investoronboarding from './investoronboarding/reducers'
import ioinvitationpreview from './ioinvitationpreview/reducers'
import ioverification from './ioverification/reducers'
import publishingDesk from './publishingDesk/reducers'
import onboardingReducer from './onboarding/reducer'
import fundentities from './fundEntities/reducers'
import investorreporting from './investorReporting/reducers'
/* import dropdown from './dropdown/reducers'
import loan from './loan/reducers'
import media from './media/reducers' */
// import userprofile from './userprofile/reducers'
//  import statements from '../pages/investor/statements/store/reducers' */

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    user,
    statements,
    qup,
    fundentities,
    menu,
    settingsmenu,
    settings,
    /* dropdown,
    loan,
    media, */
    entity,
      ioverification,
    // userprofile,
    general,
    investordashboard,
    dropdownmenu,
    team,
    advisor,
    userForm,
    investoronboarding,
    ioinvitationpreview,
    /* statements, */
    publishingDesk,
    onboardingReducer,
    investorreporting,
    countdownTimerReducer,
  })
