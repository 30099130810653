import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu, Row, Col } from 'antd'
import ClassNames from 'classnames'

import PersonalInfo from '../../../pages/shared/setting/useredit/PersonalInfo.js'
import Accounts from '../../../pages/shared/setting/internalusers/containers/EditUserContainer'
import EntityUser from '../../../pages/shared/setting/entityuser'
import Sidebar from '../../../pages/shared/setting/applicationsettings'
import Tables from '../../../pages/shared/setting/pricingcards'
import UserAvatar from './UserAvatar'

import styles from './style.module.scss'

const mapStateToProps = ({ settings, settingsmenu }) => ({
  settings,
  settingsmenu,
})

@connect(mapStateToProps)
@connect(({ user }) => ({ user }))
class Settings extends Component {
  closeSettings = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSettingsOpen',
        value: false,
      },
    })
  }

  changeSetting = input => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuSettingsValue',
        value: input,
      },
    })
  }

  render() {
    const {
      user,
      settings: { isSettingsOpen, menuSettingsValue, isRoundedCorners },
      settingsmenu: { settingsMenuData },
    } = this.props

    const {
       name
    } = user

    return (
      <div
        className={isSettingsOpen ? `${styles.settingsWrapper} closeSettings` : styles.hidden}
        role="presentation"
        onClick={event => {
          if (event.target.nodeName === 'DIV') {
            if (event.target.className.includes('closeSettings')) {
              this.closeSettings()
            }
          }
        }}
      >
        <div className={isSettingsOpen ? styles.settingsOpened : ''}>
          <div className={styles.container}>
            <div className={styles.exit}>
              <button
                className={`${styles.close} fa fa-times`}
                onClick={this.closeSettings}
                type="button"
              />
            </div>
            <Row gutter={16}>
              <Col span={6} className={styles.sideMenu}>
                <Menu
                  className={ClassNames(
                    { idealCard: isRoundedCorners },
                    styles.navigation,
                  )}
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  defaultOpenKeys={['sub1']}
                >
                  {settingsMenuData.map(menu => (
                    <Menu.Item
                      key={menu.key}
                      className={
                        menuSettingsValue === menu.select
                          ? 'ant-menu-item ant-menu-item-selected'
                          : ''
                      }
                      onClick={() => {
                        this.changeSetting(menu.select)
                      }}
                    >
                      <span className={styles.tabIcon}>
                        <i className={menu.className} />
                        <span>{menu.title}</span>
                      </span>
                    </Menu.Item>
                  ))}
                </Menu>
              </Col>
              <Col span={18}>
                <Row className={styles.topRow}>
                  <Col span={24}>
                    <UserAvatar />
                    <div className={ClassNames(styles.userInfo, styles.bottomSpace, styles.idealCard)}>
                      <strong>
                        Welcome, {`${name}` || 'Test Person'}
                      </strong>
                      <br />
                      Manage your personal information and account settings.
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className={ClassNames(styles.centeredCard, styles.bottomSpace)}>
                      {menuSettingsValue === 'Profile' ? <PersonalInfo /> : null}
                      {menuSettingsValue === 'Accounts' ? <Accounts /> : null}
                      {menuSettingsValue === 'Entity User' ? <EntityUser /> : null}
                      {menuSettingsValue === 'Application Settings' ? <Sidebar /> : null}
                      {menuSettingsValue === 'Payments & Services' ? <Tables /> : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

export default Settings
