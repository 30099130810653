import React, { Component, Fragment } from 'react'
import { Col, Form, Radio, Row, Select,  } from 'antd'
import { Field } from 'redux-form'

import RadioField from 'components/AppComponents/FormSection/formFields/RadioField'
import InputFieldComponent from 'components/AppComponents/FormSection/component/InputFieldComponent'
import SelectField from 'components/AppComponents/FormSection/formFields/SelectField'
import CheckboxFieldComponent from '../component/CheckboxFieldComponent'
import PhysicalAddress from './PhysicalAddress'
import styles from '../../../../pages/investor/onboarding/onboardingStyle.module.scss'

const { Option } = Select

class FinancialSettings extends Component {
  state = {
    contribution: null,
    periodic: null,
    receivePaymentType: '',
    isTaxAdvisor: '',
  }

  handleBlur = (event) => {
    event.preventDefault()
  }

  render() {
    const { contribution, periodic, receivePaymentType, isTaxAdvisor } = this.state

    return (
      <Fragment>
        <div
          className={{
          textAlign: "left",
          borderBottom: "solid #928c84 4px",
          width: "100%"
          }}
        >
          <h2 className=" h2Border">Financial Information</h2>
        </div>

        <Row>
          <Col span={24}>
            <div className="stretchSelectField">
              <Form.Item
                label="Contribution Transfer Via"
                labelCol={{span: 12}}
                onChange={(e) => {
                  this.setState({ contribution: e.target.value })
                }}
              >
                <RadioField name="contributionTransfer">
                  <Radio value="ACH">ACH</Radio>
                  <Radio value="Wire">Wire</Radio>
                  <Radio value="Check">Check</Radio>
                </RadioField>
              </Form.Item>
              {(contribution === 'ACH' || contribution === 'Wire') && (
                <Form.Item
                  label="Financial Institution"
                  labelCol={{span: 12}}
                >
                  <div style={{ width: '286px' }}>
                    <Field
                      name="financialInstitution"
                      component={InputFieldComponent}
                      placeholder="Financial Institution"
                    />
                  </div>
                </Form.Item>
              )}

              <Form.Item
                label="Periodic Contributions?"
                labelCol={{span: 12}}
                onChange={(e) => {
                  this.setState({ periodic: e.target.value })
                }}
              >
                <RadioField name="periodicContributions">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </RadioField>
              </Form.Item>
              {periodic === 'yes' && (
                <Form.Item
                  label="Frequency?"
                  labelCol={{span: 12}}
                >
                  <div style={{ width: '286px' }}>
                    <SelectField
                      name="contributionFrequency"
                      placeholder="Frequency"
                      handleBlur={this.handleBlur}
                    >
                      <Option value="Monthly">Monthly</Option>
                      <Option value="Quarterly">Quarterly</Option>
                      <Option value="Yearly">Yearly</Option>
                    </SelectField>
                  </div>
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>

        <Form.Item label="How will you receive payments?" labelCol={{span: 12}}>
          <RadioField name="receivePayments" onChange={(e) => this.setState({receivePaymentType: e.target.value})}>
            <Radio value="check">Check</Radio>
            <Radio value="wire">Wire</Radio>
            <Radio value="ach">ACH</Radio>
          </RadioField>
        </Form.Item>
        {receivePaymentType === 'check' && (
          <Fragment>
            <Form.Item label=" " labelCol={{span: 12}}>
              <Field
                name="sameAddress"
                component={CheckboxFieldComponent}
              >
                Same as previous address
              </Field>
            </Form.Item>
            <Form.Item label="Physical Address" labelCol={{span: 12}}>
              <PhysicalAddress />
            </Form.Item>
          </Fragment>
        )}
        {(receivePaymentType === 'wire' || receivePaymentType === 'ach') && (
          <Form.Item label=" " labelCol={{span: 12}}>
            NOTE: You will be asked to provide this information at time of your distribution.
          </Form.Item>
        )}

        <Form.Item label="Electronic Monthly Statements " labelCol={{span: 12}}>
          <RadioField name="monthlyStatement">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </RadioField>
        </Form.Item>
        <Form.Item label="Fund Management Notifications" labelCol={{span: 12}}>
          <RadioField name="managementNotification">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </RadioField>
        </Form.Item>
        <Form.Item label="Tax Advisor Year End Statements" labelCol={{span: 12}}>
          <RadioField name="taxAdvisorStatements">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </RadioField>
        </Form.Item>

        <Form.Item label="Tax Advisor Communication Authorization" labelCol={{span: 12}}>
          <div className={styles.taxAdvisorWrapper}>
            <RadioField name="taxAdvisorCommunication" onChange={(e) => this.setState({isTaxAdvisor: e.target.value})}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioField>
          </div>
        </Form.Item>

        {isTaxAdvisor === 'yes' && (
          <Form.Item label="Tax Advisor Email" labelCol={{span: 12}}>
            <Field name="taxAdvisorEmail" component={InputFieldComponent} placeholder="Email" />
          </Form.Item>
        )}
      </Fragment>
    )
  }
}

export default FinancialSettings

