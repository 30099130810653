const actions = {
  UPDATE_REVIEW_REDUCER: 'onboarding/UPDATE_REVIEW_REDUCER',
  UPDATE_SUCCESS: 'onboarding/UPDATE_SUCCESS',
  FETCH_INVESTMENT_DETAIL: 'onboarding/FETCH_INVESTMENT_DETAIL',
  FETCH_ACCOUNT_DETAIL: 'onboarding/FETCH_ACCOUNT_DETAIL',
  FETCH_IDENTITY_DETAILS: 'onboarding/FETCH_IDENTITY_DETAILS',
  SUBMIT_ONBOARDING_DETAIL: 'onboarding/SUBMIT_ONBOARDING_DETAIL',
  SUBMIT_ONBOARDING_DETAIL_SUCCESS: 'onboarding/SUBMIT_ONBOARDING_DETAIL_SUCCESS',
  SUBMIT_ONBOARDING_IDENTITY: 'onboarding/SUBMIT_ONBOARDING_IDENTITY',
  SUBMIT_ENTITY_DETAILS: 'onboarding/SUBMIT_ENTITY_DETAILS',
  SUBMIT_ENTITY_DETAILS_SUCCESS: 'onboarding/SUBMIT_ENTITY_DETAILS_SUCCESS',
  SUBMIT_ACCOUNT_DETAILS: 'onboarding/SUBMIT_ACCOUNT_DETAILS',
  SUBMIT_ACCOUNT_DETAILS_SUCCESS: 'onboarding/SUBMIT_ACCOUNT_DETAILS_SUCCESS',
  SUBMIT_VERIFICATION_SUCCESS: 'onboarding/SUBMIT_VERIFICATION_SUCCESS',
  SEND_ONBOARDING_DOCUSIGN: 'onboarding/SEND_ONBOARDING_DOCUSIGN',
}

export default actions
