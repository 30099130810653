import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import store from 'store'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import classNames from 'classnames'

import SearchField from 'components/AppComponents/FormSection/component/SearchFieldComponent'
import Auth0 from 'services/auth0'
import styles from './menuLeftStyle.module.scss'

const { Sider } = Layout
const { SubMenu, Divider } = Menu
// const [ expanded, setExpanded] = useState(false)
// need to collapse the menus
// https://medium.com/gammastack/making-a-nested-sidebar-menu-in-react-f8595031995e
// or

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuLeftData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isSettingsOpen: settings.isSettingsOpen,
  isLightTheme: settings.isLightTheme,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  logoUrlExpanded: settings.logoUrlExpanded,
  logoUrlCollapsed: settings.logoUrlCollapsed,
  accountType: user.accountType,
  accountStatus: user.accountStatus,
  entityMembershipsStatus: user.entityMembershipsStatus,
})

@withRouter
@connect(mapStateToProps, (dispatch) => ({ dispatch }))
class MenuLeft extends React.Component {

  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  componentDidMount() {
    this.setSelectedKeys(this.props)

    const { dispatch, accountType, accountStatus } = this.props

    return new Promise((resolve, reject) => {
      dispatch({
        type: 'menu/GET_DATA',
        resolve,
        reject,
        accountType,
        accountStatus,
      })
    })
  }

  setSelectedKeys = (props) => {
    const { menuData } = this.props
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = _.find(flattenItems(menuData, 'children'), [
      'url',
      props.location.pathname,
    ])
    this.setState({
      selectedKeys: selectedItem ? [selectedItem.key] : [],
    })
  }

  onCollapse = (value, type) => {
    const { dispatch, isMenuCollapsed } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })

    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = (openedKeys) => {
    const lastOpenedMenu = [_.last(openedKeys)]
    store.set('app.menu.openedKeys', lastOpenedMenu)
     this.setState({
       openedKeys: lastOpenedMenu,
     })
  }

  handleClick = (e) => {
    const { dispatch, isSettingsOpen, isMobileView, isMobileMenuOpen } = this.props

    store.set('app.menu.selectedKeys', [e.key])
    // close menu if mobile menu opened
    if (isMobileView) {
      setTimeout(() => {
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'isMobileMenuOpen',
            value: !isMobileMenuOpen,
          },
        })
      }, 500)
    }
    // custom action on settings menu item
    if (e.key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSettingsOpen',
          value: !isSettingsOpen,
        },
      })
      return
    }

    if (e.key === 'logout') {
      dispatch({
        type: 'user/LOGOUT',
      })
      store.set('app.menu.selectedKeys', [])
      store.set('app.menu.openedKeys', [])
    }

    this.setState({
      selectedKeys: [e.key],
      // openKeys: e.keyPath,
    })
  }

  generateMenuItems = () => {
    const { menuData = [], entityMembershipsStatus } = this.props
    const generateItem = (item) => {
      const { key, title, url, icon, disabled } = item
      if (!Auth0.userHasRole(item.role).hasRole) {
        return null
      }
      if (item.divider) {
        return <Divider key={Math.random()} />
      }
      if (item.url) {
        const rootUrl = item.url.split('/').pop()
        const pathname = window.location.pathname.split('/').pop()
        const isMenuSelected = rootUrl === pathname

        return (
          <Menu.Item
            key={key}
            disabled={disabled}
            className={isMenuSelected ? 'ant-menu-item ant-menu-item-selected' : ''}
            title={title}
          >
            {item.target ? (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>{title}</span>
              </a>
            ) : (
              <NavLink to={url}>
                {icon && <span className={`${icon} ${styles.icon}`} />}
                <span className={styles.title}>
                  {title.length > 16 ? `${title.substring(0, 16)}...` : title}
                </span>
              </NavLink>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={styles.title}>{title}</span>
          {icon && <span className={`${icon} ${styles.icon}`} />}
        </Menu.Item>
      )
    }

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map((menuItem) => {
      if (menuItem.children) {
        if (!Auth0.userHasRole(menuItem.role).hasRole) {
          return null
        }

        if (menuItem.status !== entityMembershipsStatus && menuItem.title === 'Lobby') {
          return null
        }

        const isMenuSelected = menuItem.key === window.location.pathname.split('/')[1]

        const subMenuTitle = (
          <span key={menuItem.key}>
            <span
              className={isMenuSelected ? `${styles.title} ${styles.activeParent}` : styles.title}
            >
              {menuItem.title}
            </span>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
          </span>
        )
        return (
          <SubMenu
            title={subMenuTitle}
            key={menuItem.key}
            onTitleClick={() => {
              this.handleTitleClick(menuItem)
            }}
          >
            {generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  onMenuSearch = (event) => {
    const { dispatch } = this.props
    dispatch({
      type: 'menu/SEARCH_ON_MENU',
      keyword: event.target.value,
    })
  }

  renderSearchField = (menuSettings) => {
    if (menuSettings.collapsed) {
      return <PrinterOutlined className={styles.searchField} />
    }
    return (
      <SearchField
        onChange={this.onMenuSearch}
        placeholder="Start typing to search"
        showPrefixIcon
      />
    )
  }

  handleTitleClick = (menuItem) => {
    if (menuItem.url) {
      const { history } = this.props

      history.push(menuItem.url)
    }
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const {
      isMobileView,
      isMenuCollapsed,
      isLightTheme,
      logoUrlExpanded,
      logoUrlCollapsed,
    } = this.props
    const menuSettings = isMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: 256,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          breakpoint: 'lg',
        }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      >
        <div className={styles.logo}>
          <div
            className={classNames(
              menuSettings.collapsed ? styles.logoContainerCollapsed : styles.logoContainer,
            )}
          >
            <img src={`${menuSettings.collapsed ? logoUrlCollapsed : logoUrlExpanded}`} alt="" />
          </div>
        </div>
        <Scrollbars
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          autoHide
        >
          <Menu
            theme={isLightTheme ? 'light' : 'dark'}
            onClick={this.handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            {menu}
          </Menu>

          <div className={styles.menuSearchWrapper}>{this.renderSearchField(menuSettings)}</div>
        </Scrollbars>
      </Sider>
    )
  }
}

export default MenuLeft
