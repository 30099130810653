import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'antd'
import Auth0 from '../../../services/auth0'
import styles from '../../LayoutComponents/TopBar/style.module.scss'

@withRouter
class AppSwitcher extends Component {
  constructor(props) {
    super(props)

    this.state = {
      investorDashboard: Auth0.userHasRole('investor_accredited').hasRole,
      fundDashboard: Auth0.userHasRole('fund_manager').hasRole,
      invitedDashboard: Auth0.userHasRole('investor_invited').hasRole,
  //    brokerDashboard: Auth0.userHasRole('broker_premium').hasRole,
    }
  }

  signIn = (data) => {
    const { dispatch } = this.props

    return new Promise((resolve, reject) => {
      dispatch({
        type: 'user/LOGIN',
        payload: data,
        resolve,
        reject,
      })
    })
      .then(() => {
        const { history } = this.props
        const investorDashboard = Auth0.userHasRole('investor_accredited').hasRole
        const invitedDashboard = Auth0.userHasRole('investor_accredited').hasRole
   //     const brokerDashboard = Auth0.userHasRole('broker_premium').hasRole
        const fundDashboard = Auth0.userHasRole('fund_manager').hasRole
        this.setState({
          investorDashboard,
          fundDashboard,
   //       brokerDashboard,
          invitedDashboard
        })

        history.push('/home')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
  //  const broker = {
  //    username: 'alex@mail.com',
  //    password: 'Wolf@123',
  //  }
    const investor = {
      username: 'investor@s2n.solutions',
      password: 'Password2020!',
    }

    const investorInvited = {
      username: 'investorinvited@s2n.solutions',
      password: 'Password2020!',
    }

    const fund = {
      username: 'fundmanager@s2n.solutions',
      password: 'Password2020!',
    }

    const { investorDashboard, fundDashboard, invitedDashboard } = this.state

    return (
      <Fragment>
        { !invitedDashboard ? (
          <Button
            className={styles.ledgerModalButton}
            type="pblue"
            shape="circle"
            size="large"
            onClick={() => this.signIn(investorInvited)}
          >
            <strong style={{color: "white"}}>II</strong>
          </Button>):null}
        { !investorDashboard ? (
          <Button
            className={styles.ledgerModalButton}
            type="primary"
            shape="circle"
            size="large"
            onClick={() => this.signIn(investor)}
          >
            <strong>IA</strong>
          </Button>
        ) : null}
        { !fundDashboard ? (
          <Button
            className={styles.ledgerModalButton}
            type="porange"
            shape="circle"
            size="large"
            onClick={() => this.signIn(fund)}
          >
            <strong>FM</strong>
          </Button>
        ) : null}
        {/* !brokerDashboard ? (
          <Button
            className={styles.ledgerModalButton}
            type="primary"
            shape="circle"
            size="large"
            onClick={() => this.signIn(broker)}
          >
            <strong>FM</strong>
          </Button>
        ) : null
        */ }
      </Fragment>
    )
  }
}

export default connect((state) => ({ accountStatus: state.user.accountStatus }), (dispatch) => ({ dispatch }))(AppSwitcher)
