import axios from 'axios'
import Auth0 from 'services/auth0'

// const auth0 = new Auth0()

export default class Api {
  customAxios = () =>
    axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/ld+json',
        Accept: 'application/json',
    //    Authorization: `Bearer ${Auth0.getAccessToken()}`,
      },
    })

  customAxiosMedia = () =>
    axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${Auth0.getAccessToken()}`,
      },
    })

  customAxiosPatch = () =>
    axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Accept: 'application/json',
        Authorization: `Bearer ${Auth0.getAccessToken()}`,
      },
    })

  get = (url, data) => {
    const self = this
    return new Promise((resolve, reject) => {
      self
        .customAxios()
        .get(url, {
          params: data,
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  post = (url, data) => {
    const self = this
    return new Promise((resolve, reject) => {
      self
        .customAxios()
        .post(url, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  postMedia = (url, data) => {
    const self = this
    return new Promise((resolve, reject) => {
      self
        .customAxiosMedia()
        .post(url, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  put = (url, data) => {
    const self = this
    return new Promise((resolve, reject) => {
      self
        .customAxios()
        .put(url, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  patch = (url, data) => {
    const self = this
    return new Promise((resolve, reject) => {
      self
        .customAxiosPatch()
        .patch(url, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  delete = (url) => {
    const self = this
    return new Promise((resolve, reject) => {
      self
        .customAxios()
        .delete(url)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
