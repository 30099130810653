import React from 'react'
import { BackTop, Layout, Spin } from 'antd'
import { connect } from 'react-redux'
// scss { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from '../../components/LayoutComponents/TopBar'
import Menu from '../../components/LayoutComponents/Menu'
// scss Footer from '../../components/LayoutComponents/Footer'
import Breadcrumbs from '../../components/LayoutComponents/Breadcrumbs'
import Settings from '../../components/LayoutComponents/Settings'
// scss SignUpForm from '../../pages/user/signup/SignUpForm/index'
import styles from './style.module.scss'

const mapStateToProps = ({ settings, user }) => ({
  isBorderless: settings.isBorderless,
  isRoundedCorners: settings.isRoundedCorners,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
  isSettingsOpen: settings.isSettingsOpen,
  menuSettingsValue: settings.menuSettingsValue,
  loading: user.loading,
})

// @withRouter
@connect(mapStateToProps)
class MainLayout extends React.PureComponent {
  render() {
    const {
      children,
      isSettingsOpen,
      menuSettingsValue,
      isBorderless,
      isRoundedCorners,
      isFixedWidth,
      isTopbarFixed,
      isMenuShadow,
      isMenuTop,
      // isInvestor,
      loading,
    } = this.props

    return (
      <Spin spinning={loading}>
        <Layout
          className={classNames({
            settings__borderLess: isBorderless,
            settings__TopbarFixed: isTopbarFixed,
            settings__squaredBorders: isRoundedCorners,
            settings__fixedWidth: isFixedWidth,
            settings__menuShadow: isMenuShadow,
            settings__settingsOpen: isSettingsOpen,
            settings__settingsValue: menuSettingsValue,
            settings__menuTop: isMenuTop,
          })}
        >
          <BackTop />
          <Menu />
          {/* <SignUpForm /> */}
          <Settings />
          <Layout>
            <Layout.Header className={styles.layoutHeader}>
              <TopBar />
            </Layout.Header>
            <Layout.Content className={styles.layoutContent}>
              <div className={`${styles.layoutBackground} ${styles.background}`} />
              <div className={styles.backgroundOverlay} />
              <div className={styles.content}>
                <Breadcrumbs />
                {children}
              </div>
            </Layout.Content>
            {/* {!isInvestor && (
              <Layout.Footer>
                <Footer />
              </Layout.Footer>
            )} */}
          </Layout>
        </Layout>
      </Spin>
    )
  }
}

export default MainLayout
