import React from 'react'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'

import styles from './LoginStyle.module.scss'

@withRouter
export default class LoginLayout extends React.PureComponent {
  render() {
    const {
      children,
      location: { pathname },
    } = this.props

    return (
      <Layout>
        <Layout.Content>
          <div
            className={classNames(
              pathname.includes('signup') ? styles.signup : styles.login,
              styles.layout,
            )}
          >
            <div className={styles.header}>
              <div className="row">
                <div className="col-lg-12">
                  <div className={styles.logo}>
                    <Link to="/">
                      <img src="/resources/images/ideal-icon.png" alt="Ideal View Logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.block}>
                <div className="row">
                  <div className="col-xl-12">
                    <div
                      className={classNames(
                        pathname.includes('signup') ? styles.signupInner : styles.loginInner,
                        styles.inner,
                      )}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={`${core.footer} text-center`}>
              <ul className="list-unstyled list-inline mb-3">
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Terms of Use</a>
                </li>
                <li className="active list-inline-item">
                  <a href="javascript: void(0);">Compliance</a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Confidential Information</a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Support</a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Contacts</a>
                </li>
              </ul>
              <p>&copy; 2019 Idealview. All rights reserved.</p>
            </div> */}
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}
