import React from 'react'
import { connect } from 'react-redux'
import { Layout, Menu } from 'antd'
import { withRouter, NavLink } from 'react-router-dom'
import Auth0 from '../../../services/auth0'
import styles from './style.module.scss'

const { Sider } = Layout

const mapStateToProps = ({ settingsmenu }) => ({
  menuData: settingsmenu.settingsMenuData,
})

@withRouter
@connect(mapStateToProps)
class SettingsMenu extends React.Component {
  generateSettingMenuItems = items => {
    const { location } = this.props
    const generateMenuItem = item => {
      const { title, key, className, url } = item

      if (!Auth0.userHasScope(item.permissionRequired).hasScope) {
        return null
      }
      const isMenuSelected =
        location.pathname === item.url ||
        (location.pathname === '/setting' && item.url === '/setting/edit-profile') ||
        (location.pathname === '/setting/users/create' && item.url === '/setting/users')
      return (
        <Menu.Item
          key={key}
          className={isMenuSelected ? 'ant-menu-item ant-menu-item-selected' : ''}
        >
          <NavLink to={url}>
            <span className={styles.tabIcon}>
              <i className={className} />
              <span>{title}</span>
            </span>
          </NavLink>
        </Menu.Item>
      )
    }
    return items.map(eachitem => generateMenuItem(eachitem))
  }

  render() {
    const { menuData } = this.props

    return (
      <Sider
        className={styles.menu}
        width="250"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken)
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type)
        }}
      >
        <Menu className={styles.navigation} mode="inline" defaultSelectedKeys={['1']}>
          {this.generateSettingMenuItems(menuData)}
        </Menu>
      </Sider>
    )
  }
}

export default SettingsMenu
