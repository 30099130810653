import { all, takeEvery, put, call, delay } from 'redux-saga/effects'
// scss { delay } from 'redux-saga'
import { push } from 'connected-react-router'
import { notification } from 'antd'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import { getCookies } from 'utils/Cookies'
// scss { GET_DATA } from 'app/redux/menu/sagas'
import Api from 'services/api/Api'
import Auth0 from 'services/auth0'
import { EntityTypes } from 'pages/shared/setting/entityTypes'
import ErrorLookup from 'utils/ErrorLookup'
import store from 'store'
import actions from './actions'
import { SAVE_RESOURCE /* handleRollback */ } from '../general/sagas'
import getEndPoints from '../endpoints'
import { SAVE_ENTITY } from '../entity/sagas'
import staticUser from './user.json'

const auth0 = new Auth0()
const api = new Api()
const cookies = getCookies()

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* USER_SIGN_UP({ resolve, reject, accountType, formData }) {
  try {
    const { PersonEntityForm, EmailEntityForm, account: accountSection } = formData

    const entity = { entityType: { id: EntityTypes.INDIVIDUAL } }
    const entityData = {
      entity,
      PersonEntityForm,
      EmailEntityForm,
    }

    const savedEntity = yield call(SAVE_ENTITY, reject, entityData)

    const account = yield call(
      SAVE_RESOURCE,
      'account',
      {
        accountType: { id: accountType },
      },
      'account',
    )

    // no delete api for account yet

    const user = yield call(
      SAVE_RESOURCE,
      'user',
      {
        ...formData.EmailEntityForm,
        ...formData.password,
        person: { id: savedEntity.savedPerson.id },
        userStatus: { id: 'active' },
        accountMemberships: [1].reduce(
          (accumulator) => [
            ...accumulator,
            {
              account: {
                id: account.id,
              },
              accountMembershipType: { id: accountSection.accountType },
            },
          ],
          [],
        ),
      },
      'user',
    )

    // no delete api for user yet

    yield call(resolve, user)
    notification.success({
      message: 'Sign up',
      description: 'You have successfully registered your account!',
    })
    yield put(push('/user/login'))
  } catch (error) {
    console.log('user_sign_up', error)
    yield call(reject, error)
  }
}

export function* MIGRATE({ payload, resolve, reject }) {
  try {
    const { migrateProfile } = getCookies()
    const { password } = payload
    const profile = JSON.parse(migrateProfile)

    // create entity
    const createEntityBody = {
      entityType: {
        id: EntityTypes.INDIVIDUAL,
      },
      person: [],
    }
    const entityId = (yield call(api.post, getEndPoints(['entity']), createEntityBody)).data[0].id

    // create person
    const createPersonBody = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      entity: {
        id: entityId,
      },
    }
    const personID = (yield call(
      api.post,
      getEndPoints(['entityPerson', entityId]),
      createPersonBody,
    )).data[0].id

    // attach entity email
    const attachEmailBody = {
      email: profile.email,
      entity: {
        id: entityId,
      },
    }
    yield call(api.post, getEndPoints(['entityContactEmail', entityId]), attachEmailBody)

    // create user
    const createUserBody = {
      email: profile.email,
      password,
      person: {
        id: personID,
      },
      userStatus: {
        id: 'active',
      },
      accountMemberships: [
        {
          account: {
            id: 200,
          },
          accountMembershipType: {
            id: 'broker_free',
          },
        },
      ],
    }
    yield call(api.post, getEndPoints(['user']), createUserBody)

    //
    //

    // set the user's status to -1 in the old database
    // this calls a Lambda function which does so
    fetch(
      `https://3zuo99ppz0.execute-api.us-east-2.amazonaws.com/default/DEV---IV-2_0-Change-Old-User-Status?id=${profile.userId}`,
      {
        method: 'POST',
        headers: {
          'x-api-key': 'fDkmcPcXZa6g4JFUsBsg195KYD4Pc9rT38SSwgv1',
        },
      },
    )
      .then((result) => result.text())
      .then((text) => {
        if (text !== 'null') {
          reject(JSON.parse(text))
        }
      })
      .catch((error) => {
        console.error(error)
      })

    // redirect to login
    yield put(push('/user/login'))
    notification.success({
      message: 'Migration Successful',
      description: "You've migrated your account! To continue, login with your new password.",
    })

    resolve('ok')
  } catch (error) {
    reject(error.description)
  }
}

export function* LOGIN({ payload, resolve, reject }) {
  try {
    const { username, password } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const authResult = yield call(auth0.login, username, password)
    if (authResult.migrate) {
      yield put(push('/user/migrate'))
    } else if (authResult.idToken) {
      notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in to S2N!',
      })
      const { idToken, accessToken } = authResult
      yield call(LOAD_CURRENT_ACCOUNT, { idToken, accessToken, username /* role: 'admin' */ })
      /*  yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
        idToken: authResult.idToken,
        accessToken: authResult.accessToken,
        role: 'admin',
      }) */
      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     authorized: true,
      //   },
      // })
    }
    resolve('ok')
  } catch (error) {
    if (error.message !== undefined) {
      reject(ErrorLookup(error.message))
    }
    reject(ErrorLookup(error.description))
  }
}

export function* GOOGLE_LOGIN({ reject }) {
  try {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const authResult = yield call(auth0.loginWithGoogle)

    if (authResult.idToken) {
      notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in to S2N!',
      })
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
        idToken: authResult.idToken,
        accessToken: authResult.accessToken,
        // role: 'admin',
      })
    }
  } catch (error) {
    reject(error.description)
  }
}

export function* CREATE_MAIN_ENTITY(formData) {
  try {
    // Create Entity
    let entity
    if (EntityTypes.BUSINESS.includes(formData.entityType)) {
      entity = yield call(CREATE_ENTITY, {
        ...formData.businessInfo,
        ...formData.incomeInfo,
        entityType: { id: formData.entityType },
      })
    } else {
      entity = yield call(CREATE_ENTITY, {
        entityType: {
          id: formData.entityType,
        },
      })
    }

    // Create Contact Address Info
    const contactAddress = yield call(SUBMIT_CONTACT_ADDRESS_INFO, formData.AddressEntityForm)

    // Pass the Contact Address Id and Entity Id to the EntityContactAddress Table
    yield call(SUBMIT_ENTITY_CONTACT_ADDRESS_INFO, entity, contactAddress)

    // Create Person Info with Entity Id
    yield call(SUBMIT_PERSON_INFO, entity, formData.PersonEntityForm)

    //  Create Conatct Email Info with Entity Id
    yield call(SUBMIT_CONTACT_EMAIL_INFO, entity, formData.EmailEntityForm)

    yield call(CREATE_ENTITY_INCOME, entity, formData.EntityIncome)

    yield call(SUBMIT_CONTACT_PHONE_INFO, entity, {
      ...formData.PhoneEntityForm,
      contactPhoneType: { id: formData.PhoneEntityForm.contactPhoneType },
    })

    return entity
  } catch (error) {
    console.log('OriginalMain Entity Ceate', error)
    throw error
  }
}

export function* CREATE_ENTITY(formData) {
  try {
    const entityResponse = yield call(api.post, getEndPoints('entity'), formData)
    const entity = {
      id: entityResponse.data[0].id,
    }
    yield put({
      type: 'CREATE_ENTITY_SUCCESS',
      entity,
    })
    return entity
  } catch (error) {
    console.log('CREATE_ENTITY', error)
  }
}

export function* SUBMIT_CONTACT_ADDRESS_INFO(formData) {
  try {
    const contactAddressResponse = yield call(api.post, '/contact-addresses', formData)
    const contactAddress = {
      id: contactAddressResponse.data[0].id,
    }
    yield put({
      type: 'SUBMIT_CONTACT_ADDRESS_INFO_SUCCESS',
      contactAddress,
    })
    return contactAddress
  } catch (error) {
    throw error
  }
}

export function* CREATE_ENTITY_INCOME(entity, formData) {
  try {
    const entityIncome = yield call(api.post, `/entities/${entity.id}/entity-incomes`, {
      ...formData,
      entity,
    })
    yield put({
      type: 'CREATE_ENTITY_INCOME_SUCCESS',
    })
    return entityIncome
  } catch (error) {
    throw error
  }
}

export function* SUBMIT_ENTITY_CONTACT_ADDRESS_INFO(entity, contactAddress) {
  try {
    yield call(api.post, `/entities/${entity.id}/entity-contact-addresses`, {
      contactAddress,
      entity,
      isPrimary: true,
    })
    yield put({
      type: 'SUBMIT_ENTITY_CONTACT_ADDRESS_INFO_SUCCESSs',
    })
  } catch (error) {
    throw error
  }
}

export function* SUBMIT_CONTACT_EMAIL_INFO(entity, formData) {
  try {
    const userResponse = yield call(api.post, getEndPoints(['entityContactEmail', entity.id]), {
      ...formData,
      entity,
      isPrimary: true,
    })
    const user = {
      id: userResponse.data[0].id,
    }
    yield put({
      type: 'SUBMIT_CONTACT_EMAIL_INFO_SUCCESS',
      user,
    })
    return user
  } catch (error) {
    console.log('error', error.response.data)
    throw { contact: error.response.data }
  }
}

export function* SUBMIT_PERSON_INFO(entity, formData) {
  try {
    const personResponse = yield call(api.post, getEndPoints(['entityPerson', entity.id]), {
      ...formData,
      entity,
    })
    const person = {
      id: personResponse.data[0].id,
    }
    yield put({
      type: 'SUBMIT_PERSON_INFO_SUCCESS',
      person,
    })
    return person
  } catch (error) {
    throw error
  }
}

export function* SUBMIT_CONTACT_PHONE_INFO(entity, formData) {
  try {
    const phoneResponse = yield call(api.post, `/entities/${entity.id}/entity-contact-phones`, {
      ...formData,
      entity,
    })
    const phone = {
      id: phoneResponse.data[0].id,
    }
    yield put({
      type: 'SUBMIT_CONTACT_PHONE_INFO_SUCCESS',
      phone,
    })
    return phone
  } catch (error) {
    throw error
  }
}

export function* SIGN_UP_VIA_ONE_TIME_CODE_WITH_EMAIL({ formData, resolve, reject }) {
  try {
    const { email } = formData
    const response = yield call(auth0.signUpViaOneTimeCodeWithEmail, email)
    if (response.Id) {
      yield call(resolve, 'Email sent successfully!')
    }
  } catch (error) {
    yield call(reject, error)
  }
}

export function* LOAD_CURRENT_ACCOUNT({ idToken, accessToken /* role */ }) {
  try {
    if (!idToken) {
      return
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
        authorized: true,
      },
    })

    const profile = yield call(Auth0.getProfileFromIdToken, idToken)
    const accessToken_ = accessToken === undefined ? Auth0.getAccessToken() : accessToken

    const roles = 'http://soup/roles'
    const status = 'https://s2n.systems/user_metadata'

    const { sub: authId } = jwtDecode(accessToken_)
    // const { [keyMembershipType]: accountmembershiptypeid } = jwtDecode(accessToken_)
    const { [roles]: userRoles } = jwtDecode(accessToken_)
    const { [status]: userStatus } = jwtDecode(accessToken_)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        accountStatus: userStatus.status,
        accountType: userRoles
      },
    })

    const userResponse = yield call(FETCH_USER, authId)

    const entityId = userResponse.person.entity.id

    const entityResponse = yield call(api.get, `/entities/${entityId}`)

    const { memberEntityMemberships, entityContactPhones } = entityResponse.data

    const { fundMemberSetting } = memberEntityMemberships[0]

    const entityMembershipId = memberEntityMemberships[0].id

    const entityMembershipResponse = yield call(api.get, `/entity_memberships/${entityMembershipId}`)

    const { entityFund } = entityMembershipResponse.data

    const investorAccountsData = []

    for(let i=0; i < fundMemberSetting.length; i +=1) {
      const accountInfo = {
        accountName: fundMemberSetting[i].accountName,
        fundEntityName: entityFund.fundName,
        fundEntityId: entityMembershipResponse.data.entityFund.id,
        investorAccountsId: fundMemberSetting[i].id,
        accountNumber: fundMemberSetting[i].accountNumber,
        fundMemberSettingsId: 1,
        id: i,
        si: (Math.random() * 10).toFixed(1),
        ytd: (Math.random() * 10).toFixed(1)
    }
      investorAccountsData.push(accountInfo)
    }
    if (profile) {
      const { sub } = profile
      yield put({
        type: 'user/SET_STATE',
        payload: {
          accessToken,
          accountId: authId,
          accountStatus: userStatus.status,
          accountType: userRoles,
          citizenship: userResponse.person.citizenshipStatus,
          dob: userResponse.person.birthDate,
          email: userResponse.emailAddress,
          entityId,
          entityType: userResponse.person.entity.entityType.id,
          firstName: userResponse.person.firstName,
          fundName: entityFund.fundName,
          lastName: userResponse.person.lastName,
          name: userResponse.person.entity.name,
          owningEntity: entityResponse.owningEntityFunds,
          person: `${userResponse.person.firstName} ${userResponse.person.lastName}`,
          phone: entityContactPhones[0] ? entityContactPhones[0].phoneNumber : "",
          preferredName: userResponse.person.preferredName,
          investorAccounts: investorAccountsData,
          sub,
          taxId: userResponse.person.entity.taxNumber,
          user: userResponse,
          userId: userResponse.id,
          entityFundId: entityFund.id
        },
      })
    }
  } catch (error) {
    console.log('error', error)
    notification.error({
      message: 'Error Loading Account Details',
      description:
        'Please try to login again. Either your session has expired or we got incorrect information. Sorry for inconvenience.',
    })
    yield put({
      type: 'user/LOGOUT',
    })
    console.log("end", moment())
    yield put(push('/user/login'))
  }
}

export function* FETCH_ACCOUNT_DETAIL(userId) {
  try {
    const accountDetail = yield call(api.get, `/users/${userId}`, {})
    const {
      person: {
        entity: { id: entityId },
      },
    } = accountDetail.data

    const entityDetail = yield call(api.get, `/entities/${entityId}`)
    const {
      entityMemberships: [
        {
          id: entityMembershipsId,
          entityMembershipType: { id: entityMembershipsStatus },
        },
      ],
    } = entityDetail.data
    accountDetail.data.entityMembershipsStatus = entityMembershipsStatus

    const entityMembershipsDetail = yield call(
      api.get,
      `/entity_memberships/${entityMembershipsId}`,
    )
    const {
      entityFund: { fundName },
    } = entityMembershipsDetail.data
    accountDetail.data.fundName = fundName

    console.log({ entityDetail, entityMembershipsDetail })

    return accountDetail.data
  } catch (error) {
    throw error
  }
}

export function FETCH_FAKE_ACCOUNT_DETAIL() {
  try {
    return staticUser
  } catch (error) {
    throw error
  }
}

export function* FETCH_ENTITY(entityId) {
  try {
    const response = yield call(api.get, `/entities/${entityId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export function* FETCH_USER( authId ) {
  try {
    const subResponse = yield call(api.get, `/user_by_sub_ids/${authId}`)
    const response = yield call(api.get, `/users/${subResponse.data}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export function* FETCH_ENTITY_INCOME(entityId) {
  try {
    return yield call(api.get, `/entities/${entityId}/entity-incomes`, {})
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

export function* FETCH_ENTITY_LIST({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/entities', {})

    yield put({
      type: actions.FETCH_ENTITY_LIST_SUCCESS,
      payload: response.data[0].results,
    })
    yield call(resolve)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* LOGOUT() {
  try {
    yield call(auth0.logout)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: false,
      },
    })

    yield put(push('/user/login'))
    // yield put({
    //   type: 'menu/GET_DATA',
    // });
    // yield put({
    //   type: 'settingsmenu/GET_DATA',
    // })
  } catch (error) {
    console.log('logout error', error)
  }
}

export function* FORGOT_PASSWORD({ payload, resolve, reject }) {
  try {
    const { email } = payload
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const forgotResult = yield call(auth0.forgotPassword, email)
    if (forgotResult.success) {
      yield put(push('/user/login'))
      notification.success({
        message: 'Password reset request submitted',
        description:
          'If an account with that email exists in our SystemComponents, you will receive an email with a link to reset your password.',
      })
    }
    resolve('ok')
  } catch (error) {
    reject(error.description)
  }
}

export function* LOAD_INVESTOR_ACCOUNT({ userId }) {
  try {
    const investorAccounts = yield call(api.get, `/entities/${userId}`)
    const temp = investorAccounts.data
    const entityFunds = temp.owningEntityFunds
    const array = []

    if(entityFunds !== undefined){
      for (let i = 0; i < entityFunds.length; i += 1) {
        const out = yield call(api.get, `${entityFunds[i].substring(4)}`)
        array.push(out.data)
      }
    }

    yield put({
      type: actions.LOAD_INVESTOR_ACCOUNT_SUCCESS,
      payload: array,
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loadingPercentage: 100,
      },
    })

    yield delay(800)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } catch (error) {
    console.log({ error })
  }
}

export function* ZIP_CODE_SEARCH({ zipCode, resolve, reject }) {
  try {
    const response = yield call(api.get, `https://ziptasticAPI.com/${zipCode}`)

    const responseData = response.data

    yield call(resolve, responseData)
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.MIGRATE, MIGRATE),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.GOOGLE_LOGIN, GOOGLE_LOGIN),
    // GET_DATA(),
    LOAD_CURRENT_ACCOUNT({ idToken: cookies.id_token }), // run once on app load to check user auth
    takeEvery(actions.FETCH_ENTITY_LIST, FETCH_ENTITY_LIST),
    takeEvery(actions.USER_SIGN_UP, USER_SIGN_UP),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.LOAD_INVESTOR_ACCOUNT, LOAD_INVESTOR_ACCOUNT),
    takeEvery(actions.ZIP_CODE_SEARCH, ZIP_CODE_SEARCH),
  ])
}
