const endpoints = {
  user: (id = '') => `users/${id}`,
  entity: (id = '') => `entities/${id}`,
  entityContactEmail: (entityId, id = '') => `entities/${entityId}/entity-contact-emails/${id}`,
  entityContactAddress: (entityId, id = '') =>
    `/entity_contact_addresses/${entityId}`,
  entityContactPhone: (entityId, id = '') => `entities/${entityId}/entity-contact-phones/${id}`,
  entityPerson: (entityId, id = '') => `entities/${entityId}/persons/${id}`,
  contactAddress: (id = '') => `contact-addresses/${id}`,
}

export default endpoints
