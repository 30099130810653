import React from 'react'
import { Field } from 'redux-form'

import InputFieldComponent from '../component/InputFieldComponent'

const DBAField = () => <Field name="city" component={InputFieldComponent} />

export default DBAField


// fix, why is there a city in this? @rojen