import { takeEvery, all, call } from 'redux-saga/effects'
import _ from 'lodash'
import Api from 'services/api/Api'
import { EntityTypes } from 'pages/shared/setting/entityTypes'
import getEndPoints from '../endpoints'
import actions from './actions'
import { SAVE_RESOURCE, handleRollback, DELETE_RESOURCE } from '../general/sagas'

const api = new Api()

export function* FETCH_ENTITY({ resolve, reject, entityId }) {
  try {
    const emailInfo = yield call(FETCH_RESOURCE, ['entityContactEmail', entityId])
    const phoneInfo = yield call(FETCH_RESOURCE, ['entityContactPhone', entityId])
    const addressInfo = yield call(FETCH_RESOURCE, ['entityContactAddress', entityId])
    if (resolve) {
      yield call(resolve, { emailInfo, phoneInfo, addressInfo })
    }
    return { emailInfo, phoneInfo, addressInfo }
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    }
    return error
  }
}

export function* FETCH_RESOURCE(endpoint) {
  try {
    const resource = yield call(api.get, getEndPoints(endpoint))
    return resource.data[0]
  } catch (error) {
    console.log(error)
    throw error.response.data
  }
}

export function* SAVE_ENTITY(reject, entityData) {
  const rollbacks = []
  try {
    let { PersonEntityForm, EmailEntityForm, PhoneEntityForm, contactAddress } = entityData
    const { entity, AddressEntityForm } = entityData

    // save entity
    const savedEntity = yield call(SAVE_RESOURCE, 'entity', entity, 'entity')
    rollbacks.unshift(getEndPoints('entity', savedEntity.id))

    // save person
    const entityType = entity.entityType && entity.entityType.id
    let savedPerson = {}
    if (EntityTypes.PERSON.includes(entityType)) {
      PersonEntityForm = {
        ...PersonEntityForm,
        entity: { id: savedEntity.id },
      }

      savedPerson = yield call(
        SAVE_RESOURCE,
        ['entityPerson', savedEntity.id],
        PersonEntityForm,
        'person',
      )
      // for updated person data in entity
      entity.person = [savedPerson]

      rollbacks.unshift(getEndPoints(['entityPerson', savedEntity.id], savedPerson.id))
    } else if (PersonEntityForm && PersonEntityForm.id) {
      yield call(DELETE_RESOURCE, getEndPoints(['entityPerson', savedEntity.id], savedPerson.id))
    }

    // save email
    EmailEntityForm = {
      ...EmailEntityForm,
      entity: { id: savedEntity.id },
    }
    const savedContactEmail = yield call(
      SAVE_RESOURCE,
      ['entityContactEmail', savedEntity.id],
      EmailEntityForm,
      'EmailEntityForm',
    )
    rollbacks.unshift(getEndPoints(['entityContactEmail', savedEntity.id], savedContactEmail.id))

    // save phone
    let savedPhone
    if (!_.isEmpty(PhoneEntityForm)) {
      PhoneEntityForm = {
        ...PhoneEntityForm,
        entity: { id: savedEntity.id },
      }
      savedPhone = yield call(
        SAVE_RESOURCE,
        ['entityContactPhone', savedEntity.id],
        PhoneEntityForm,
      )
      rollbacks.unshift(getEndPoints(['entityContactPhone', savedEntity.id], savedPhone.id))
    }

    // save address
    let savedAddress
    let savedContactAddress
    if (!_.isEmpty(AddressEntityForm)) {
      savedAddress = yield call(SAVE_RESOURCE, 'contactAddress', AddressEntityForm)
      rollbacks.unshift(getEndPoints('contactAddress', savedAddress.id))

      contactAddress = {
        ...contactAddress,
        entity: { id: savedEntity.id },
        contactAddress: { id: savedAddress.id },
      }
      savedContactAddress = yield call(
        SAVE_RESOURCE,
        ['entityContactAddress', savedEntity.id],
        contactAddress,
      )
      rollbacks.unshift(getEndPoints(['entityContactAddress', entity.id], savedContactAddress.id))
    }

    return {
      savedEntity,
      savedPerson,
      savedContactEmail,
      savedPhone,
      savedAddress,
      savedContactAddress,
    }
  } catch (error) {
    yield all(rollbacks.map(handleRollback))
    yield call(reject, error)
  }
}

// fetch list entity contact phone
export function* FETCH_USER_CONTACT_PHONE(entityId) {
  try {
    const contactPhone = yield call(api.get, getEndPoints(['entityContactPhone', entityId]), {})
    return contactPhone
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

// fetch list entity contact email
export function* FETCH_USER_CONTACT_EMAIL(entityId) {
  try {
    const contactEmail = yield call(api.get, getEndPoints(['entityContactEmail', entityId]), {})
    return contactEmail
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

// fetch list entity person
export function* FETCH_ENTITY_PERSON(entityId) {
  try {
    const persons = yield call(api.get, getEndPoints(['entityPerson', entityId]), {})
    return persons
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

// fetch list entity contact address
export function* GET_USER_CONTACT_ADDRESS(entityId) {
  try {
    const contactAddress = yield call(api.get, getEndPoints(['entityContactAddress', entityId]), {})
    return contactAddress
  } catch (error) {
    console.log('error', error)
    throw error
  }
}

export function* FETCH_USER_CONTACT_ADDRESS({ entityId, resolve, reject }) {
  try {
    const contactAddress = yield call(api.get, getEndPoints(['entityContactAddress', entityId]), {})
    yield call(resolve, contactAddress)
  } catch (error) {
    yield call(reject, error)
    console.log('error', error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ENTITY, FETCH_ENTITY),
    takeEvery(actions.FETCH_RESOURCE, FETCH_RESOURCE),
    takeEvery(actions.FETCH_USER_CONTACT_ADDRESS, FETCH_USER_CONTACT_ADDRESS),
  ])
}
