import { takeEvery, all, call, put } from 'redux-saga/effects'

import actions from './actions'
import activeInvitationsData from './mockData/data'

export function* CHANGE_INVESTOR_NAME({ payload: { value } }) {
  // yield store.set(`app.qup.${pointCategory}`, value)
  yield put({
    type: 'investorVerification/SET_STATE',
    payload: {
      investorName: value,
    },
  })
}

export function* FETCH_ACTIVE_INVITATIONS({ resolve, reject }) {
  try {
    yield call(resolve, activeInvitationsData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* ON_DECLINE_INVESTOR({ resolve, reject }) {
  try {
    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* ON_APPROVE_INVESTOR({ resolve, reject }) {
  try {
    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ACTIVE_INVITATIONS, FETCH_ACTIVE_INVITATIONS),
    takeEvery(actions.ON_DECLINE_INVESTOR, ON_DECLINE_INVESTOR),
    takeEvery(actions.ON_APPROVE_INVESTOR, ON_APPROVE_INVESTOR),
    takeEvery(actions.CHANGE_INVESTOR_NAME, CHANGE_INVESTOR_NAME),
  ])
}
