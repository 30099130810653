import React from 'react'
import { Field } from 'redux-form'

import InputFieldComponent from '../component/InputFieldComponent'

const CountryField = ({placeholder}) => (
  <Field name="country" disabled placeholder={placeholder} component={InputFieldComponent} />
)

export default CountryField
