import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import InputFieldComponent from '../component/InputFieldComponent'

const { emptyValidator, nameValidator } = Validators

const LastNameField = () => (
  <Field
    name="lastName"
    placeholder="Last Name"
    maxLength={25}
    component={InputFieldComponent}
    validate={[emptyValidator, nameValidator]}
  />
)

export default LastNameField
