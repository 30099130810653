import { lazy } from 'react'

import { sharedRoutes } from '../shared/routes'

const fundRoutes = [

  // Investor Management Application CRM Portal
  {
    path: '/ima/crm/investor/',
    Component: lazy(() => import('pages/fund/investorCRM')),
    role: 'fund_manager',
    exact: true,
    key: 26
  },
  {
    path: '/ima/crm/investor-invite',
    Component: lazy(() => import('pages/fund/investorInvitation')),
    role: 'fund_manager',
    exact: true,
    key: 27
  },
  {
    path: '/ima/crm/verification',
    Component: lazy(() => import('pages/fund/investorVerification')),
    role: 'fund_manager',
    exact: true,
    key: 28
  },
  {
    path: '/ima/crm/invitation-preview',
    Component: lazy(() => import('pages/fund/ioinvitationpreview')),
    role: 'fund_manager',
    exact: true,
    key: 29
  },
  {
    path: '/ima/crm/due-diligence',
    Component: lazy(() => import('components/SystemComponents/Construction/')),
    role: 'fund_manager',
    exact: true,
    key: 30
  },
  {
    path: '/ima/crm/coming-soon',
    Component: lazy(() => import('pages/shared/comingSoon')),
    role: 'fund_manager',
    exact: true,
    key: 31
  },



// Investor Management Application Investor Portal


  {
    path: '/ima/investor-portal',
    Component: lazy(() => import('pages/fund/investorReporting')),
    role: 'fund_manager',
    exact: true,
    key: 32
  },
  {
    path: '/ima/investor-reporting',
    Component: lazy(() => import('pages/fund/investorReporting')),
    role: 'fund_manager',
    exact: true,
    key: 33
  },
  {
    path: '/ima/advisors',
    Component: lazy(() => import('pages/fund/advisor')),
    role: 'fund_manager',
    exact: true,
    key: 34
  },

  // Fund Management Application

  {
    path: '/fma/wealth-management',
    Component: lazy(() => import('pages/fund/fundManagerDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 35
  },
  {
    path: '/fma/schedule',
    Component: lazy(() => import('pages/fund/fundEntities')),
    role: 'fund_manager',
    exact: true,
    key: 36
  },
  {
    path: '/fma/qup/data',
    Component: lazy(() => import('pages/fund/QUP/Input')),
    role: 'fund_manager',
    exact: true,
    key: 37
  },
  {
    path: '/fma/qup/reports',
    Component: lazy(() => import('pages/fund/QUP/Report')),
    role: 'fund_manager',
    exact: true,
    key: 38
  },
  {
    path: '/fma/ledger/fund-ledger',
    Component: lazy(() => import('pages/fund/ledgers/fundLedger')),
    role: ['fund_manager'],
    exact: true,
    key: 39
  },
  {
    path: '/fma/ledger/general-ledger',
    Component: lazy(() => import('pages/fund/ledgers/fundLedger')),
    role: ['fund_manager'],
    exact: true,
    key: 40
  },
  {
    path: '/fma/fund-modeling/Construction',
    Component: lazy(() => import('components/SystemComponents/Construction/')),
    role: 'fund_manager',
    exact: true,
    key: 41
  },


  // Fund Admin Application FAA
  // dashboard
  {
    path: '/faa/dashboard',
    Component: lazy(() => import('pages/fund/fundAdminDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 42
  },
  // Schedules
  {
    path: '/faa/fund-schedules',
    Component: lazy(() => import('pages/fund/fundEntities')),
    role: 'fund_manager',
    exact: true,
    key: 43
  },
  // Ledger
  {
    Component: lazy(() => import('pages/fund/faa_ledgers/fundLedger')),
    role: ['fund_manager'],
    exact: true,
    key: 44
  },
  // construction
  {
    path: '/faa/client-billing',
    Component: lazy(() => import('components/SystemComponents/Construction/')),
    role: 'fund_manager',
    exact: true,
    key: 45
  },
  {
    path: '/faa/client-profiles',
    Component: lazy(() => import('pages/fund/fundEntities')),
    role: 'fund_manager',
    exact: true,
    key: 46
  },
  // TOOLS for FUND MANAGER and FUND ADMIN application
  {
    path: '/publishing',
    Component: lazy(() => import('pages/fund/publishing')),
    role: ['fund_manager'],
    exact: true,
    key: 47
  },
  {
    path: '/publishing/designer',
    Component: lazy(() => import('pages/fund/publishingDesk')),
    role: ['fund_manager'],
    exact: true,
    key: 48
  },
  {
    path: '/publishing/preview',
    Component: lazy(() => import('pages/fund/publishingDeskPreview')),
    role: ['fund_manager'],
    exact: true,
    key: 49
  },

  ...sharedRoutes,
]

export default fundRoutes
