const actions = {
  FETCH_INVESTOR_FUNNEL_DATA: 'fund/FETCH_INVESTOR_FUNNEL_DATA',
  FETCH_OPEN_DEALS_DATA: 'fund/FETCH_OPEN_DEALS_DATA',
  FETCH_STATEMENTS_DATA: 'fund/FETCH_STATEMENTS_DATA',
  FETCH_MONTHLY_CAPITAL_DATA: 'fund/FETCH_MONTHLY_CAPITAL_DATA',
  FETCH_DEALS_BY_STAGES_DATA: 'fund/FETCH_DEALS_BY_STAGES_DATA',
  FETCH_LEADS_BY_SOURCE_DATA: 'fund/FETCH_LEADS_BY_SOURCE_DATA',
  FETCH_ANNUAL_CAPITAL_DATA: 'fund/FETCH_ANNUAL_CAPITAL_DATA',
  FETCH_INVESTOR_MANAGEMENT_TABLE_DATA: 'fund/FETCH_INVESTOR_MANAGMENT_TABLE_DATA',
}

export default actions
