import { takeEvery, call, all, put /* Daniel, put */ } from 'redux-saga/effects'

import Api from 'services/api/Api'
import getEndPoints from '../endpoints'
import actions from './actions'

const api = new Api()

export function* FETCH_INVESTMENT_DETAIL({ resolve, reject, entityId, userId }) {
  try {
    const entityResponse = yield call(api.get, `/entities/${entityId}`)
    console.log("entityResponse", entityResponse)

    const accountTypesResponse = yield call(api.get, getEndPoints('entityTypes'))
    console.log("accountTypesResponse", accountTypesResponse)

    const userResponse = yield call(api.get, `/users/${userId}`)
    console.log("userResponse", userResponse.data)

    const response = {
      user: userResponse.data,
      entity: entityResponse.data,
      accountTypes: accountTypesResponse.data,
      investorAccount: entityResponse.data.memberEntityMemberships[0].investorAccounts ? entityResponse.data.memberEntityMemberships[0].investorAccounts[0]: []
    }
    yield call(resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_ACCOUNT_DETAIL({ resolve, reject, entityId }) {
  try {
    const response = yield call(api.get, `/entities/${entityId}`)

    yield call(resolve, response.data)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_IDENTITY_DETAILS({ resolve, reject, userId, entityId }) {
  try {
    const userResponse = yield call(api.get, `/users/${userId}`)
    const entityResponse = yield call(api.get, `/entities/${entityId}`)

    const response = {
      user: userResponse.data,
      entity: entityResponse.data,
    }
    yield call(resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SUBMIT_ONBOARDING_DETAIL({
  resolve,
  reject,
  investmentDetailData,
  contributionData,
  settingsData,
  entityId,
}) {
  try {
    yield call(api.put, getEndPoints('entities', entityId), investmentDetailData)

    console.log("investmentDetailData", investmentDetailData)
    console.log("contributionData", contributionData)

    if(contributionData !== undefined){
      const investorAccountsId = contributionData.id
      delete contributionData.id
      yield call(api.put, getEndPoints('investorAccounts', investorAccountsId), contributionData)
    }

    const response = yield call(api.get, getEndPoints('entities', entityId))

    if (response.data.memberEntityMemberships[0].investorAccounts[0] === undefined ) {
      settingsData.entityMembership = `/api/entity_memberships/${response.data.memberEntityMemberships[0].id}`

      yield call(api.post, getEndPoints('settings'), settingsData)
    }
    else{
      const membershipId = response.data.memberEntityMemberships[0].investorAccounts[0].id
      yield call(api.put, getEndPoints('settingsUpdate', membershipId), settingsData)
    }


    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SUBMIT_ONBOARDING_IDENTITY({ resolve, reject, onboardingIdentityData, entityId }) {
  try {

    const onboardingEntityData = onboardingIdentityData.person.entity
    delete onboardingIdentityData.person.entity
    yield call(api.put, getEndPoints('entities', entityId), onboardingEntityData)

    const { id: userId } = onboardingIdentityData
    delete onboardingIdentityData.id
    yield call(api.put, getEndPoints('users', userId), onboardingIdentityData)


    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SUBMIT_ENTITY_DETAILS({
  resolve,
  reject,
  formValues,
  entityId
}) {
  try {
    const {sameAsPersonal, contactInformation} = formValues
    if(sameAsPersonal === "no"){
      const contactAddresses = {
        address1: contactInformation.streetOne,
        address2: contactInformation.streetTwo ? contactInformation.streetTwo : null,
        city: contactInformation.city,
        state: contactInformation.investorState && `/api/geo_states/${contactInformation.investorState}`,
        postalCode: contactInformation.zip,
      }
      const response = yield call(api.post,'contact_addresses', contactAddresses )

      const entityAddressData = {
        entity: `/api/entities/${entityId}`,
        isPrimary: true,
        contactAddress: `/api/contact_addresses/${response.data.id}`
      }
      yield call(api.post,'entity_contact_addresses', entityAddressData )
    }
    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SUBMIT_ACCOUNT_DETAILS({ resolve, reject , onboardingAccountDetailsData, entityId }) {
  try {
    const response = yield call(api.get, getEndPoints('entities', entityId))
    const membershipId = response.data.memberEntityMemberships[0].investorAccounts[0].id
    yield call(api.put, getEndPoints('settingsUpdate', membershipId), onboardingAccountDetailsData)

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SEND_ONBOARDING_DOCUSIGN({ resolve, reject, docusignData, commData }) {
  try {
    yield call(api.post, '/communications', commData)
    const response = yield call(api.post, 'docusign_integrations', docusignData)
    yield call(resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* UPDATE_REVIEW_REDUCER({
  resolve,
  reject,
}) {
  yield put({
    type: actions.SUBMIT_ENTITY_DETAILS_SUCCESS,
    payload: {
      fromReview: true,
    },
  })

  try {
    yield call(resolve, "success")
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVESTMENT_DETAIL, FETCH_INVESTMENT_DETAIL),
    takeEvery(actions.FETCH_ACCOUNT_DETAIL, FETCH_ACCOUNT_DETAIL),
    takeEvery(actions.FETCH_IDENTITY_DETAILS, FETCH_IDENTITY_DETAILS),
    takeEvery(actions.SUBMIT_ONBOARDING_DETAIL, SUBMIT_ONBOARDING_DETAIL),
    takeEvery(actions.SUBMIT_ONBOARDING_IDENTITY, SUBMIT_ONBOARDING_IDENTITY),
    takeEvery(actions.SUBMIT_ENTITY_DETAILS, SUBMIT_ENTITY_DETAILS),
    takeEvery(actions.SUBMIT_ACCOUNT_DETAILS, SUBMIT_ACCOUNT_DETAILS),
    takeEvery(actions.UPDATE_REVIEW_REDUCER, UPDATE_REVIEW_REDUCER),
    takeEvery(actions.SEND_ONBOARDING_DOCUSIGN, SEND_ONBOARDING_DOCUSIGN),

  ])
}
