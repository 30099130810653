import React from 'react'
import { Field } from 'redux-form'

// scss Validators from 'app/utils/Validators'
import InputFieldComponent from '../component/InputFieldComponent'

// const { emptyValidator } = Validators

const ZipField = ({ placeholder, onBlur }) => (
  <Field
    name="zip"
    placeholder={placeholder}
    maxLength={10}
    component={InputFieldComponent}
    onBlur={onBlur}
    // validate={[emptyValidator]}
  />
)

export default ZipField
