import { takeEvery, all, call } from 'redux-saga/effects'
import Api from 'services/api/Api'

import actions from './actions'

const api = new Api()

export function* FETCH_STATEMENTS_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/investor_statements/11')
    response.data[0].results.shift()
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_INVESTOR_FUNNEL_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/CRMInvestorFunnel')
    yield call (resolve, response.data.data)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_OPEN_DEALS_DATA({ resolve, reject, entityId }) {
  try {
    const response = yield call(api.get, `/entities/${entityId}`)
    const firstEntity = response.data.owningEntityFunds[0] ? response.data.owningEntityFunds[0] : undefined
    if(firstEntity){
      const temp = firstEntity ? firstEntity.substring(4) : firstEntity
      const tableData = firstEntity ? yield call(api.get, temp) : firstEntity
      yield call(resolve, firstEntity ? tableData.data.entity.entityMemberships : [])
    }
    else{
      yield call(resolve, [])
    }

  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_MONTHLY_CAPITAL_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/CRMMonthlyCapital')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_DEALS_BY_STAGES_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/CRMDealsByStages')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_LEADS_BY_SOURCE_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/CRMLeadsBySource')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_ANNUAL_CAPITAL_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/CRMAnnualCapital')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_INVESTOR_MANAGEMENT_TABLE_DATA({ resolve, reject, entityId }) {
  try {
    const response = yield call(api.get, `/entities/${entityId}`)
    const entities = response.data.owningEntityFunds

    const array = []
    for (let i = 0; i < entities.length; i += 1) {
      const temp = entities[i].substring(4)
      const out = yield call(api.get, temp)

      const members = out.data.entity.entityMemberships
      for (let j = 0; j < members.length; j += 1) {
        const outData = out.data.entity.entityMemberships
        outData[j].name = out.data.entity.name
        if((outData[j].entityMembershipType !== undefined) && (outData[j].entityMembershipType.name === 'Investor Invited')){
          array.push(outData[j])
        }
      }
    }
    yield call(resolve, array)
  } catch (error) {
    yield call(reject, error)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVESTOR_FUNNEL_DATA, FETCH_INVESTOR_FUNNEL_DATA),
    takeEvery(actions.FETCH_OPEN_DEALS_DATA, FETCH_OPEN_DEALS_DATA),
    takeEvery(actions.FETCH_STATEMENTS_DATA, FETCH_STATEMENTS_DATA),
    takeEvery(actions.FETCH_MONTHLY_CAPITAL_DATA, FETCH_MONTHLY_CAPITAL_DATA),
    takeEvery(actions.FETCH_DEALS_BY_STAGES_DATA, FETCH_DEALS_BY_STAGES_DATA),
    takeEvery(actions.FETCH_LEADS_BY_SOURCE_DATA, FETCH_LEADS_BY_SOURCE_DATA),
    takeEvery(actions.FETCH_ANNUAL_CAPITAL_DATA, FETCH_ANNUAL_CAPITAL_DATA),
    takeEvery(actions.FETCH_INVESTOR_MANAGEMENT_TABLE_DATA, FETCH_INVESTOR_MANAGEMENT_TABLE_DATA),
  ])
}
