import React, { Component, Fragment } from 'react'
import { Form, Row, Col } from 'antd'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import ContactInformation from 'components/AppComponents/FormSection/formParts/ContactInformation'
import DBAField from 'components/AppComponents/FormSection/formFields/DBAField'
import BusinessTypeField from 'components/AppComponents/FormSection/formFields/BusinessTypeField'
import TaxNumberField from 'components/AppComponents/FormSection/formFields/TaxNumberField'
import IncorporationStateField from 'components/AppComponents/FormSection/formFields/IncorporationStateField'
import TermsField from 'components/AppComponents/FormSection/formFields/TermsField'
import styles from './userEditStyle.module.scss'
import FinancialSettings from '../../../../components/AppComponents/FormSection/formParts/FinancialSettings'
import FirstNameField from '../../../../components/AppComponents/FormSection/formFields/FirstNameField'
import MiddleInitialField from '../../../../components/AppComponents/FormSection/formFields/MiddleInitialField'
import LastNameField from '../../../../components/AppComponents/FormSection/formFields/LastNameField'



const formItemLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: '100%',
  },
}

const FORM_NAME='PersonalInfo'

class PersonalInfo extends Component {
  render() {

    return (
      <Fragment>
        <Form layout="horizontal" {...formItemLayout} colon={false}>
          <h2 className=" h2Border">Personal Information</h2>
          <Form.Item label="First Name">
            <FirstNameField />
          </Form.Item>
          <Form.Item label="Middle Name">
            <MiddleInitialField />
          </Form.Item>
          <Form.Item label="Last Name">
            <LastNameField />
          </Form.Item>

          <ContactInformation formName={FORM_NAME} />

          <div className={styles.header}>
            <h2 className=" h2Border">Company Information</h2>
          </div>

          <Row>
            <Col span={12}>
              <div className="stretchSelectField">
                <Form.Item label="DBA">
                  <DBAField />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="stretchSelectField">
                <Form.Item label="Business Type">
                  <BusinessTypeField />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div className="stretchSelectField">
                <Form.Item label="Tax ID">
                  <TaxNumberField />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="stretchSelectField">
                <Form.Item label="Incorportation State">
                  <IncorporationStateField />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="stretchSelectField">
                <Form.Item label="Notes">
                  <TermsField />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <FinancialSettings />

        </Form>
      </Fragment>
    )
  }
}

export default compose(
  reduxForm({
    form: FORM_NAME,
  }),
  connect(
    state => ({
      user: state.user,
    }),
    {},
  ),
)(PersonalInfo)

