import { all, call, takeEvery } from 'redux-saga/effects'

import actions from './actions'

export function* FETCH_NEWS({ resolve, reject }) {
  try {
    const tempNews = [
      {
        id: 1,
        newsImage:
          'https://images.pexels.com/photos/3183183/pexels-photo-3183183.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        newsTitle: 'Management Team',
        newsCommenters: [
          {
            id: 3,
            pictureUrl: '/resources/images/avatars/3.jpg',
          },
          {
            id: 1,
            pictureUrl: '/resources/images/avatars/1.jpg',
          },
          {
            id: 2,
            pictureUrl: '/resources/images/avatars/2.jpg',
          },

          {
            id: 4,
            pictureUrl: '/resources/images/avatars/4.jpg',
          },
        ],
        publishedDate: '05/06/2021',
      },
      {
        id: 2,
        newsImage:
          'https://images.pexels.com/photos/97080/pexels-photo-97080.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        newsTitle: 'Resources for Markets',
        newsCommenters: [
          {
            id: 2,
            pictureUrl: '/resources/images/avatars/2.jpg',
          },
          {
            id: 4,
            pictureUrl: '/resources/images/avatars/4.jpg',
          },
          {
            id: 3,
            pictureUrl: '/resources/images/avatars/3.jpg',
          },

          {
            id: 1,
            pictureUrl: '/resources/images/avatars/1.jpg',
          },
        ],
        publishedDate: '05/06/2021',
      },
      {
        id: 3,
        newsImage:
          'https://images.pexels.com/photos/509922/pexels-photo-509922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        newsTitle: 'Covid Update',
        newsCommenters: [
          {
            id: 1,
            pictureUrl: '/resources/images/avatars/1.jpg',
          },
          {
            id: 2,
            pictureUrl: '/resources/images/avatars/2.jpg',
          },
          {
            id: 3,
            pictureUrl: '/resources/images/avatars/3.jpg',
          },
          {
            id: 4,
            pictureUrl: '/resources/images/avatars/4.jpg',
          },
        ],
        publishedDate: '05/06/2021',
      },
      {
        id: 4,
        newsImage:
          'https://images.pexels.com/photos/377903/pexels-photo-377903.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        newsTitle: 'Coffee Shop',
        newsCommenters: [
          {
            id: 1,
            pictureUrl: '/resources/images/avatars/1.jpg',
          },
          {
            id: 2,
            pictureUrl: '/resources/images/avatars/2.jpg',
          },
          {
            id: 3,
            pictureUrl: '/resources/images/avatars/3.jpg',
          },
        ],
        publishedDate: '05/06/2021',
      },
    ]

    yield call(resolve, tempNews)
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_NEWS, FETCH_NEWS)])
}
