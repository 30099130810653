import React from 'react'
import { Field } from 'redux-form'

// scss Validators from 'app/utils/Validators'
import InputFieldComponent from '../component/InputFieldComponent'

// const { emptyValidator } = Validators

const StreetOneField = ({ placeholder }) => (
  <Field
    name="streetOne"
    placeholder={placeholder}
    component={InputFieldComponent}
    // validate={[emptyValidator]}
  />
)

export default StreetOneField
