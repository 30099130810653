import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Menu, Dropdown, Avatar, Badge, Row, Col } from 'antd'
import { UserOutlined } from '@ant-design/icons'
// scss Auth0 from 'services/auth0'
import actions from '../../../../redux/entity/actions'
import styles from './style.module.scss'

// const auth0 = new Auth0()

const accountRoles = {
  broker_premium: 'Broker (Premium)',
  broker_free: 'Broker (Free)',
  broker_staff: 'Broker Staff',
  fund_manager: 'Fund Manager',
  investor_invited: 'Investor Invited',
  investor_accredited: 'Investor Accredited',
  fund_administrator: 'Fund Administrator',
}

const accountTypes = {
  broker: 'Broker',
  fund: 'Broker',
}

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    count: 7,
    // loading: false
  }

  componentDidMount() {
    this.fetchUserContactAddress()
  }

  fetchUserContactAddress = () => {
    const {
      dispatch,
      user: { entityId },
    } = this.props

    return new Promise((resolve, reject) => {
      dispatch({
        type: actions.FETCH_USER_CONTACT_ADDRESS,
        entityId,
        resolve,
        reject,
      })
    }).catch(error => {
      console.log(error)
    })
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  openSettings = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSettingsOpen',
        value: true,
      },
    })
  }

  changeSetting = input => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuSettingsValue',
        value: input,
      },
    })
  }

  getCurrentAccountType = type => accountTypes[type]

  getAccountRole = role => accountRoles[role]

  render() {
    const { user } = this.props

    const { email, name, accountType } = user

    const accountPicturePath = (user.accountPicture && user.accountPicture.path) || null

    const menu = (
      <Menu className={styles.customMenu} selectable={false}>
        <Menu.Item
          key='Profile'
          onClick={() => {
            this.openSettings()
            this.changeSetting('Profile')
          }}
          className={styles.customMenuItem}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Avatar
                src={
                  accountPicturePath &&
                  `https://funding-dev-pictures-documents.s3.us-east-2.amazonaws.com/${accountPicturePath}`
                }
                className={styles.profileMenuAvatar}
                shape="circle"
                size="large"
                icon={<UserOutlined />}
              />
            </Col>
            <Col span={18}>
              <Fragment>
                <strong>{name || 'Anonymous'}</strong>
              </Fragment>
              <div style={{overflow: "hidden"}}>{email || '-'}</div>
              <Fragment>{this.getAccountRole(accountType[0]) || 'N/A'}</Fragment>
            </Col>
          </Row>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key='Application Settings'
          onClick={() => {
            this.openSettings()
            this.changeSetting('Application Settings')
          }}
          className={styles.customMenuItem}
        >
          <i className={`${styles.menuIcon} fa fa-cog`} />
          SETTINGS
        </Menu.Item>
        {this.getAccountRole(accountType) === 'Broker (Free)' ||
        this.getAccountRole(accountType) === 'Investor (Free)' ? (
          <Menu.Item
            key='Payments & Services'
            onClick={() => {
              this.openSettings()
              this.changeSetting('Payments & Services')
            }}
            className={styles.customMenuItem}
          >
            <i className={`${styles.menuIcon} fas fa-arrow-up`} />
            Upgrade to Premium
          </Menu.Item>
        ) : null}
        <Menu.Divider />
        <Menu.Item
          key='Exit'
          className={styles.customMenuItem}
        >
          <Link to="#" onClick={this.logout} className={styles.topbarLink}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            SIGN OUT
          </Link>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.addCount} arrow={false}>
        <div className={styles.dropdown}>
          <Badge>
            {/* count={count} */}
            <Avatar
              src={
                accountPicturePath &&
                `https://funding-dev-pictures-documents.s3.us-east-2.amazonaws.com/${accountPicturePath}`
              }
              className={styles.avatar}
              size="large"
              icon={<UserOutlined />}
            />
          </Badge>
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
