const actions = {
  SUBMIT_IO_INVITATION: 'investorInvitation/SUBMIT_IO_INVITATION',
  SUBMIT_IO_INVITATION_REDUCERS: 'investorInvitation/SUBMIT_IO_INVITATION_REDUCERS',
  SUBMIT_IO_INVITATION_SUCCESS: 'investorInvitation/SUBMIT_IO_INVITATION_SUCCESS',
  FETCH_FUND_AND_ACCOUNT_TYPES: 'investorInvitation/FETCH_FUND_AND_ACCOUNT_TYPES',
  FETCH_INVESTOR: 'investorInvitation/FETCH_INVESTOR',
  FETCH_INVESTOR_SUCCESS: 'investorInvitation/FETCH_INVESTOR_SUCCESS',
  SEARCH_INVESTOR: 'investorInvitation/SEARCH_INVESTOR',
}

export default actions
