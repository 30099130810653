import actions from './actions'

const initialState = {
  entityId: '',
  userId: '',
  formType: '',
  fund: null,
  accountType: '',
  expectedContribution: '',
  isExistingInvestor: '',
  firstName: '',
  middleInitial: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  sms: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: '',
  state: '',
  city: '',
  country: 'USA',
  entityMembershipId: '',
  communicationsId: '',
  userSoftwareAccountsId: '',
  userSoftwareAccountMembershipsId: '',
}

export default function investorOnBoardingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SUBMIT_IO_INVITATION_SUCCESS:
      return { ...state, ...action.payload }
    case actions.FETCH_INVESTOR_SUCCESS:
      return {
        ...state,
        communicationsId: action.payload.communicationsId,
        userSoftwareAccountsId: action.payload.userSoftwareAccountsId,
        userSoftwareAccountMembershipsId: action.payload.userSoftwareAccountMembershipsId,
      }
    default:
      return { ...state }
  }
}
