import React, { Component } from 'react'
import { Select, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'

import SelectFieldComponent from 'components/AppComponents/FormSection/component/SelectFieldComponent'
import actions from 'redux/investoronboarding/actions'

const { Option } = Select

class SelectSearchField extends Component {
  constructor(props) {
    super(props)

    this.searchInvestor = debounce(this.searchInvestor, 800)

    this.state = {
      data: [],
      value: [],
      fetching: false,
    }
  }

  searchInvestor = (email) => {
    if (email !== '') {
      this.setState({
        fetching: true,
      })

      const { dispatch } = this.props

      new Promise((resolve, reject) => {
        dispatch({
          type: actions.SEARCH_INVESTOR,
          resolve,
          reject,
          email: email.toLowerCase(),
        })
      })
        .then((response) => {
          const investors = response.map((investor) => {
            const {
              id,
              person: { firstName, lastName },
            } = investor

            return {
              value: id,
              text: `${firstName} ${lastName}`,
            }
          })

          this.setState({
            data: investors,
            fetching: false,
          })
        })
        .catch((error) => console.log({ error }))
    }
  }

  handleChange = (value) => {
    const { dispatch, initializeWithExistingInvestor, handleLoadingInvestor } = this.props
    const { key } = value

    handleLoadingInvestor(true)

    new Promise((resolve, reject) => {
      dispatch({
        type: actions.FETCH_INVESTOR,
        resolve,
        reject,
        userId: key,
      })
    })
      .then((response) => {
        initializeWithExistingInvestor(response)
        handleLoadingInvestor(false)
      })
      .catch((error) => console.log({ error }))
  }

  render() {
    const { fetching, data, value } = this.state
    const { handleBlur } = this.props

    return (
      <Field
        name="investorSearch"
        component={SelectFieldComponent}
        showSearch
        labelInValue
        value={value}
        placeholder="enter name to search..."
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={this.searchInvestor}
        onChange={this.handleChange}
        onBlur={handleBlur}
        suffixIcon={<SearchOutlined />}
      >
        {data.map((d) => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Field>
    )
  }
}

export default connect(null, (dispatch) => ({ dispatch }))(SelectSearchField)
