import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import {Link} from "react-router-dom";

import FeatureFlag from '../../DevComponents/FeatureFlag'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'
import InvestorBrokerSwitcher from '../../AppComponents/AppSwitcher'
import LedgerEntryModal from '../../AppComponents/LedgerEntryModal'

@connect( (dispatch) => ({ dispatch }))
class TopBar extends React.Component {

  showModal = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'general/CHANGE_LEDGER_MODAL_STATUS',
      payload: {
        value: true,
      },
    })
  }

  render() {
    return (
      <div className={styles.topbar}>
        <div className={styles.topbarContents}>
          <div className={styles.inviteButtonsWrapper}>
            <Link to="/ima/crm/investor-invite">
              <Button
                className={styles.quickButton}
                type="primary"
                shape="circle"
                size="large"
              >
                <strong>LP+</strong>
              </Button>
            </Link>
          </div>
          <div className={styles.inviteButtonsWrapper}>
            <Link to="/ima/crm/due-dilligence">
              <Button
                className={styles.quickButton}
                type="primary"
                shape="circle"
                size="large"
              >
                <strong>DD+</strong>
              </Button>
            </Link>
          </div>
          <Fragment>
            <Button
              className={styles.ledgerModalButton}
              type="primary"
              shape="circle"
              size="large"
              onClick={this.showModal}
            >
              <strong><strong>L</strong></strong>
            </Button>
            <LedgerEntryModal />
          </Fragment>
          <Fragment>
            <FeatureFlag feature="appSwitcher">
              <InvestorBrokerSwitcher />
            </FeatureFlag>
          </Fragment>
          <ProfileMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
