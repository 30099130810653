import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import InputFieldComponent from '../component/InputFieldComponent'

const { emptyValidator, nameValidator } = Validators

const FirstNameField = ( { onBlur } ) => (
  <Field
    name="firstName"
    placeholder="First Name"
    maxLength={25}
    component={InputFieldComponent}
    validate={[emptyValidator, nameValidator]}
    onBlur={onBlur}
  />
)

export default FirstNameField
