import React from 'react'
import { Field } from 'redux-form'

// scss Validators from 'app/utils/Validators'
import SelectFieldComponent from '../component/SelectFieldComponent'

// const { emptyValidator } = Validators

const SelectField = ({ name, children, placeholder, handleBlur, mode, allowClear, onChange, defaultValue }) => (
  <Field
    name={name}
    component={SelectFieldComponent}
    onBlur={handleBlur}
    onChange={onChange}
    mode={mode}
    allowClear={allowClear}
    placeholder={placeholder}
    defaultValue={defaultValue}
    // validate={required && [emptyValidator]}
  >
    {children}
  </Field>
)

export default SelectField
