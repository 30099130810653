export default async function getSettingsMenu() {
  return [
    {
      title: 'Personal Information',
      key: 'profile',
      className: 'icmn-user',
      select: 'Profile',
    },
    {
      title: 'Entity User',
      key: 'Entity_User',
      className: 'fa fa-user-plus',
      select: 'Entity User',
    },
    {
      title: 'Accounts',
      key: 'accounts',
      className: 'fa fa-users',
      select: 'Accounts',
    },
    {
      title: 'Application Settings',
      key: 'application-setting',
      className: 'fa fa-cog',
      select: 'Application Settings',
    },
    {
      title: 'Payments & Services',
      key: 'payments-services',
      className: 'fa fa-credit-card',
      select: 'Payments & Services',
    },
    {
      title: 'Security',
      key: 'security',
      className: 'fa fa-lock',
      select: 'Security',
    },
  ]
}
