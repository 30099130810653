const actions = {
  SET_STATE: 'user/SET_STATE',
  SIGN_UP: 'user/SIGN_UP',
  LOGIN: 'user/LOGIN',
  GOOGLE_LOGIN: 'user/GOOGLE_LOGIN',
  MIGRATE: 'user/MIGRATE',
  SET_GOOGLE_INFO: 'user/SET_GOOGLE_INFO',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  SIGN_UP_VIA_ONE_TIME_CODE_WITH_EMAIL: 'user/SIGN_UP_VIA_ONE_TIME_CODE_WITH_EMAIL',
  FETCH_ACCOUNT_DETAIL: 'user/FETCH_ACCOUNT_DETAIL',
  FETCH_USER_CONTACT_PHONE: 'user/FETCH_USER_CONTACT_PHONE',
  FETCH_ENTITY_PERSON: 'user/FETCH_ENTITY_PERSON',
  FETCH_ENTITY_LIST: 'user/FETCH_ENTITY_LIST',
  FETCH_ENTITY_LIST_SUCCESS: 'user/FETCH_ENTITY_LIST_SUCCESS',
  USER_SIGN_UP: 'user/USER_SIGN_UP',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  LOAD_INVESTOR_ACCOUNT: 'user/LOAD_INVESTOR_ACCOUNT',
  LOAD_INVESTOR_ACCOUNT_SUCCESS: 'user/LOAD_INVESTOR_ACCOUNT_SUCCESS',
  ZIP_CODE_SEARCH: 'user/ZIP_CODE_SEARCH',
  ZIP_CODE_SEARCH_SUCCESS: 'user/ZIP_CODE_SEARCH_SUCCESS',
  CHANGE_SETTING: 'user/CHANGE_SETTING',
}

export default actions
