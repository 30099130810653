import React, { Component } from 'react'
import { Form, Radio } from 'antd'
import { connect } from 'react-redux'
import { change, FormSection } from 'redux-form'

import RadioField from 'components/AppComponents/FormSection/formFields/RadioField'
import EmailField from 'components/AppComponents/FormSection/formFields/EmailField'
import PhoneNumberField from 'components/AppComponents/FormSection/formFields/PhoneNumberField'
import StreetOneField from 'components/AppComponents/FormSection/formFields/StreetOneField'
import StreetTwoField from 'components/AppComponents/FormSection/formFields/StreetTwoField'
import CityField from 'components/AppComponents/FormSection/formFields/CityField'
import StateField from 'components/AppComponents/FormSection/formFields/StateField'
import CountryField from 'components/AppComponents/FormSection/formFields/CountryField'
import ZipField from 'components/AppComponents/FormSection/formFields/ZipField'
import actions from 'redux/user/actions'

import styles from './styles/style.module.scss'

class ContactInformation extends Component {

  zipCodeLocation = (zip) => {
    const { dispatch, updateField, formName } = this.props

    new Promise((resolve, reject) => {
      dispatch({
        type: actions.ZIP_CODE_SEARCH,
        zipCode: zip,
        resolve,
        reject,
      })
    })
      .then((response) => {
        dispatch(updateField(formName, "contactInformation.investorState", response.state))
        dispatch(updateField(formName, "contactInformation.city", response.city))
      })
      .catch((error) => console.log({ error }))
  }

  render() {
    const { checkEmailExistence, emailExists, requiredEmail } = this.props

    return (
      <FormSection name="contactInformation">
        <Form.Item required={requiredEmail} label="Email Address" validateStatus={emailExists ? "error" : undefined}>
          <EmailField name="email" placeholder="Email Address" required={requiredEmail} emailExistenceValidator={checkEmailExistence} />
        </Form.Item>
        <Form.Item label="Phone Number">
          <div className={styles.multiFormItem}>
            <div className={styles.phoneNumberField}>
              <PhoneNumberField placeholder="Phone Number" />
            </div>
            <div className={styles.inlineFormItem}>
              <RadioField name="isMobile" label="Mobile">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </RadioField>
            </div>
          </div>
        </Form.Item>
        <Form.Item label="Physical Address">
          <StreetOneField placeholder="Address Line 1" />
        </Form.Item>
        <Form.Item label=" ">
          <StreetTwoField placeholder="Address Line 2" />
        </Form.Item>
        <Form.Item label="Postal Code">
          <div className={styles.phoneNumberField}>
            <ZipField placeholder="Postal Code" onBlur={e => this.zipCodeLocation(e.target.value)} />
          </div>
          <div className={styles.stateField}>
            <StateField />
          </div>
        </Form.Item>
        <Form.Item label="City">
          <CityField placeholder="City" />
        </Form.Item>
        <Form.Item label="Country">
          <CountryField placeholder="Country" />
        </Form.Item>
      </FormSection>
    )
  }
}

export default connect(null, (dispatch) => {
  return{
    dispatch,
    updateField: (formName, field, data) => dispatch(change( formName, field, data ))
  }
},
  )(ContactInformation)

