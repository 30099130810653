const publishingDraft = [
  {
    key: '1',
    type: 'News',
    description: 'Breaking news - Josh Jane',
    lastEditDate: '2020-01-01',
    createdDate: '2019-04-12',
  },
  {
    key: '2',
    type: 'News',
    description: 'Breaking news - Paul Lang',
    lastEditDate: '2020-02-10',
    createdDate: '2018-12-18',
  },
  {
    key: '3',
    type: 'Bio',
    description: 'Jose Burch - Quality Supervisor',
    lastEditDate: '2020-05-21',
    createdDate: '2020-05-14',
  },
]

const approvalNeeded = [
  {
    key: '1',
    type: 'Bio',
    description: 'Robert Griffin - Accountant',
    lastEditDate: '2019-10-12',
    createdDate: '2019-05-01',
  },
  {
    key: '2',
    type: 'News',
    description: 'Album Released - Royal Smith',
    lastEditDate: '2020-01-01',
    createdDate: '2019-10-12',
  },
  {
    key: '3',
    type: 'Bio',
    description: 'Scott Reed - Research Agent',
    lastEditDate: '2020-01-01',
    createdDate: '2019-10-12',
  },
]

const history = [
  {
    key: '1',
    type: 'News',
    description: 'Moon Landing - Amy Clark',
    lastEditDate: '2019-09-30',
    createdDate: '2019-01-22',
  },
  {
    key: '2',
    type: 'Bio',
    description: 'Sarah Couch - Marketing',
    lastEditDate: '2020-06-18',
    createdDate: '2019-05-01',
  },
  {
    key: '3',
    type: 'Bio',
    description: 'Mike Smith - Research Director',
    lastEditDate: '2020-01-27',
    createdDate: '2019-10-01',
  },
]

export { publishingDraft, approvalNeeded, history }
