import actions from './actions'

const initialState = {
  sameAsPersonal: false,
  fromReview: false,
  userResponse: '',
  entityTypeId: '',
  detailFormValues: '',
  accountDetailFormValues: '',
  verificationFormValues: '',
}

export default function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SUBMIT_ENTITY_DETAILS_SUCCESS:
      return { ...state, ...action.payload }
    case actions.SUBMIT_ONBOARDING_DETAIL_SUCCESS:
      return {
        ...state,
        entityTypeId: action.payload.entityTypeId,
        detailFormValues: action.payload.detailFormValues,
      }
    case actions.SUBMIT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetailFormValues: action.payload.accountDetailFormValues,
      }
    case actions.SUBMIT_VERIFICATION_SUCCESS:
      console.log('verification', action.payload)
      return {
        ...state,
        verificationFormValues: action.payload.verificationFormValues,
      }
    default:
      return state
  }
}
