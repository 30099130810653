import { takeEvery, all, call, put } from 'redux-saga/effects'

import Api from 'services/api/Api'
import getEndPoints from '../endpoints'
import actions from './actions'

const api = new Api()

export function* EDIT_IO_INVITATION({ resolve, reject }) {
  try {
    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SEND_IO_INVITATION({
  resolve,
  reject,
  communicationData,
  entityMembershipsData,
  entityMembershipId,
  userSoftwareAccountsData,
  isExistingInvestor,
}) {
  try {
    console.log('entityMemberships endpoint', getEndPoints('entityMemberships', entityMembershipId))
    let method
    let communicationsEndPoint
    let userSoftwareAccountsEndPoint

    const { id: communicationsId } = communicationData
    const { id: userSoftwareAccountsId } = userSoftwareAccountsData
    delete communicationData.id
    delete userSoftwareAccountsData.id

    if (isExistingInvestor) {
      console.log('in put', getEndPoints('communications', communicationsId), {
        communicationsId,
        isExistingInvestor,
      })
      method = api.put
      communicationsEndPoint = getEndPoints('communications', communicationsId)
      userSoftwareAccountsEndPoint = getEndPoints('userSoftwareAccounts', userSoftwareAccountsId)
    } else {
      method = api.post

      delete userSoftwareAccountsData.userSoftwareAccountMemberships[0]['@id']

      communicationsEndPoint = getEndPoints('communications')
      userSoftwareAccountsEndPoint = getEndPoints('userSoftwareAccounts')
    }

    yield call(method, communicationsEndPoint, communicationData)
    yield call(
      api.patch,
      getEndPoints('entityMemberships', entityMembershipId),
      entityMembershipsData,
    )
    yield call(method, userSoftwareAccountsEndPoint, userSoftwareAccountsData)

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* ON_TEMPLATE_SELECT({ resolve, reject, template }) {
  try {
    yield put({
      type: actions.ON_TEMPLATE_SELECT_SUCCESS,
      payload: template,
    })

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* CHANGE_INVESTOR_NAME({ payload: { value } }) {
  yield put({
    type: 'iopreview/SET_STATE',
    payload: {
      investorName: value,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.EDIT_IO_INVITATION, EDIT_IO_INVITATION),
    takeEvery(actions.SEND_IO_INVITATION, SEND_IO_INVITATION),
    takeEvery(actions.ON_TEMPLATE_SELECT, ON_TEMPLATE_SELECT),
    takeEvery(actions.CHANGE_INVESTOR_NAME, CHANGE_INVESTOR_NAME),
  ])
}
