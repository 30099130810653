import React, { Component } from 'react'
import { Form, Radio } from 'antd'
import { FormSection } from 'redux-form'
import { connect } from 'react-redux'

import RadioField from 'components/AppComponents/FormSection/formFields/RadioField'
import FirstNameField from 'components/AppComponents/FormSection/formFields/FirstNameField'
import MiddleInitialField from 'components/AppComponents/FormSection/formFields/MiddleInitialField'
import LastNameField from 'components/AppComponents/FormSection/formFields/LastNameField'
import SearchAndSelectField from 'components/AppComponents/FormSection/formFields/SearchAndSelectField'

import FormPartContainer from './FormPartContainer'
import styles from './styles/style.module.scss'

// This prevents select field from being empty on onBlur event.

class NameContainer extends Component {

  handleBlur = (event) => {
    event.preventDefault()
  }

  changeInvestorName = (event) => {
    const { dispatch } = this.props
    dispatch({
      type: 'iopreview/CHANGE_INVESTOR_NAME',
      payload: {
        value: event.target.value,
      },
    })
  }

render() {
  const {
    title,
    onChange,
    showExistingInvestorField,
    isExistingInvestor,
    initializeWithExistingInvestor,
    handleLoadingInvestor
  }
    = this.props

    return(
      <FormSection name="investorName">
        <FormPartContainer>
          {title && (<h3 className="h3Border">{title}</h3>)}
          {showExistingInvestorField && (
            <Form.Item label="Existing Investor?">
              <div className={styles.isExistingInvestorBlock}>
                <RadioField name="isExistingInvestor" onChange={onChange}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </RadioField>
                {isExistingInvestor === 'yes' && (
                  <div className={styles.searchAndSelectField}>
                    <SearchAndSelectField
                      handleBlur={this.handleBlur}
                      initializeWithExistingInvestor={initializeWithExistingInvestor}
                      handleLoadingInvestor={handleLoadingInvestor}
                    />
                  </div>
                )}
              </div>
            </Form.Item>
          )}

          <Form.Item label="First Name" required>
            <FirstNameField requiredMark onBlur={this.changeInvestorName} />
          </Form.Item>
          <Form.Item label="Middle Name">
            <MiddleInitialField />
          </Form.Item>
          <Form.Item label="Last Name" required>
            <LastNameField />
          </Form.Item>
        </FormPartContainer>
      </FormSection>
    )
  }
}

export default connect(null, dispatch => ({ dispatch }))(NameContainer)
