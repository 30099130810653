import React from 'react'
import { Layout } from 'antd'

import Settings from '../../components/LayoutComponents/SettingsMenu'
import styles from './style.module.scss'
// scss UserSetting from './UserSetting';

const { Content } = Layout

class SettingsLayout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Layout className={styles.layout}>
        <Settings />
        <Content>
          <div className="utils__content">{children}</div>
        </Content>
      </Layout>
    )
  }
}

export default SettingsLayout
