const activeInvitationsData = [
  {
    key: '1',
    lpname: 'George Wilson',
    advisor: 'Tim Wade',
    phone: '801-322-1421',
    status: 'Completed',
    amount: 'Completed',
    fund: 'Growth Fund',
    iostatus: 'success',
  },
  {
    key: '2',
    lpname: 'Robert Mailard',
    advisor: 'Tim Wade',
    phone: '801-432-8521',
    status: 'Tax Document',
    amount: 'Pending',
    fund: 'Diversified Fund',
    iostatus: 'pending',
  },
  {
    key: '3',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '4',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '5',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '6',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '7',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '8',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '9',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '10',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '11',
    lpname: 'Ted Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
  {
    key: '12',
    lpname: 'Stephen Rike',
    advisor: 'Tim Wade',
    phone: '801-381-4298',
    status: 'DDR 65%',
    amount: '$125,000',
    fund: 'Growth Fund',
    iostatus: 'failed',
  },
]

export default activeInvitationsData
