import React from 'react'
import { Select } from 'antd'

const SelectFieldComponent = ({ input, meta, children, refProps, ...custom }) => {
  const { value, onChange, onBlur, defaultValue } = input
  const { error, touched } = meta
  const hasError = touched && error !== undefined
  const { placeholder } = custom

  return (
    <div style={{width: "100%"}} className={hasError ? 'has-error' : ''}>
      <Select
        value={value ? value : placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
        {...custom}
        ref={refProps}
      >
        {children}
      </Select>
    </div>
  )
}
export default SelectFieldComponent
