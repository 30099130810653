import { lazy } from 'react'

import { sharedRoutes } from '../shared/routes'

const investorRoutes = [
  {
    path: '/home',
    Component: lazy(() => import('pages/investor/investorDashboard')),
    role: 'investor_accredited',
    exact: true,
    key: 13
  },
  {
    path: '/accounts',
    Component: lazy(() => import('pages/investor/accounts')),
    role: 'investor_accredited',
    exact: true,
    key: 14
  },
  {
    path: '/accounts/statements',
    Component: lazy(() => import('pages/investor/statements')),
    role: 'investor_accredited',
    exact: true,
    key: 15
  },
  {
    path: '/accounts/:accountId',
    Component: lazy(() => import('pages/investor/accountStatements')),
    role: 'investor_accredited',
    exact: true,
    key: 16
  },
  {
    path: '/lobby/onboarding',
    Component: lazy(() => import('pages/investor/onboarding/lobby')),
    role: 'investor_accredited',
    exact: true,
    key: 17
  },
  {
    path: '/onboarding/investment-detail',
    Component: lazy(() => import('pages/investor/onboarding/investmentDetails')),
    role: 'investor_accredited',
    key: 18
  },
  {
    path: '/onboarding/identity',
    Component: lazy(() => import('pages/investor/onboarding/identity')),
    role: 'investor_accredited',
    exact: true,
    key: 19
  },
  {
    path: '/onboarding/entity-details',
    Component: lazy(() => import('pages/investor/onboarding/entityDetails')),
    role: 'investor_accredited',
    exact: true,
    key: 20
  },
  {
    path: '/onboarding/account-details',
    Component: lazy(() => import('pages/investor/onboarding/accountDetails')),
    role: 'investor_accredited',
    exact: true,
    key: 21
  },
  {
    path: '/onboarding/verification',
    Component: lazy(() => import('pages/investor/onboarding/verification')),
    role: 'investor_accredited',
    exact: true,
    key: 22
  },
  {
    path: '/onboarding/questionnaire',
    Component: lazy(() => import('pages/investor/onboarding/subDocsQuestionnaire')),
    role: 'investor_accredited',
    exact: true,
    key: 23
  },
  {
    path: '/onboarding/review',
    Component: lazy(() => import('pages/investor/onboarding/review')),
    role: 'investor_accredited',
    exact: true,
    key: 24
  },
  {
    path: '/onboarding/submission',
    Component: lazy(() => import('pages/investor/onboarding/submission')),
    role: 'investor_accredited',
    exact: true,
    key: 25
  },

  ...sharedRoutes,
]

export default investorRoutes


// todo rename investor to ima investor management application
