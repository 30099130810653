import actions from './actions'

const initialState = {
  accountId: '',
  userId: '',
  email: '',
  person: '',
  firstName: '',
  lastName: '',
  preferredName: '',
  bioTitle: '',
  bioParagraph: '',
  citizenship: '',
  title: '',
  preferredTitle: '',
  taxId: '',
  dob: '',
  phone: '',
  accountType: '',
  accountStatus: '',
  currentAccountType: '',
  fundName: '',
  entityList: '',
  loading: false,
  authorized: false,
  role: 'admin',
  name: '',
  picture: '',
  sub: '',
  accessToken: '',
  googleInfo: '',
  accountPicture: '',
  loadingPercentage: 0,
  investorAccounts: [],
  entityType: [],
  owningEntity: '',
  entityId: '',
  user: '',
  entityMembershipsStatus: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_GOOGLE_INFO:
      return { ...state, googleInfo: action.payload }
    case actions.FETCH_ENTITY_LIST_SUCCESS:
      return { ...state, entityList: action.payload }
    case actions.LOAD_INVESTOR_ACCOUNT_SUCCESS:
      return { ...state, investorAccounts: action.payload }
    case actions.ZIP_CODE_SEARCH_SUCCESS:
      return { results: action.payload }
    default:
      return state
  }
}
