import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import InputFieldComponent from '../component/InputFieldComponent'

const { emptyValidator, emailValidator } = Validators

const EmailField = ({ name, placeholder, required, emailExistenceValidator }) => (
  <Field
    name={name}
    placeholder={placeholder}
    maxLength={50}
    component={InputFieldComponent}
    onBlur={e => emailExistenceValidator(e.target.value)}
    validate={required && [emptyValidator, emailValidator]}
  />
)

export default EmailField
