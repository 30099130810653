import { takeEvery, all, put, call } from 'redux-saga/effects'
// scss Api from 'app/services/api/Api'
// scss getEndPoints from '../endpoints'

import actions from './actions'

// const api = new Api()

const demoTeamMembers = [
  {
    id: 1,
    fullName: 'Laila Jansen',
    department: 'Accounting',
    title: 'Head of Accounting',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/people/1.jpg',
    hasBio: 'true',
  },
  {
    id: 2,
    fullName: 'Don Williams',
    department: 'Sales',
    title: 'Sales Team Lead',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/people/2.jpg',
  },
  {
    id: 3,
    fullName: 'Mary Thompson',
    department: 'Support',
    title: 'It Lead',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/people/3.jpg',
  },
  {
    id: 4,
    fullName: 'Cristian Gregory',
    department: 'Admin',
    title: 'Admin Manager',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/people/4.jpg',
  },
  {
    id: 5,
    fullName: 'Darin Anderson',
    department: 'Accounting',
    title: 'Director of Accounting',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/people/5.jpg',
  },
  {
    id: 6,
    fullName: 'Nathan White',
    department: 'Support',
    title: 'Support Analyst',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/people/1.jpg',
  },
]

const demoLeadership = [
  {
    id: 1,
    fullName: 'Sean Clark',
    title: 'CEO',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/avatars/1.jpg',
  },
  {
    id: 2,
    fullName: 'Kellen Jones',
    title: 'President',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/avatars/4.jpg',
  },
  {
    id: 3,
    fullName: 'Austin Pritchett',
    title: 'Chief Financial Officer',
    phoneNumber: '(871) 567-5896',
    email: 'support@prospera.fund',
    pictureUrl: '/resources/images/avatars/1.jpg',
  },
]

const demoBoard = [
  {
    id: 1,
    fullName: 'Courtney Williams',
    title: 'Head of Board',
    pictureUrl: '/resources/images/avatars/5.jpg',
    twitterURL: 'https://www.twitter.com/',
    linkedInURL: 'https://www.linkedin.com/',
    instagramURL: 'https://www.instagram.com/',
  },
  {
    id: 2,
    fullName: 'Hannah Sanchez',
    title: 'Board Member',
    pictureUrl: '/resources/images/avatars/2.jpg',
    twitterURL: 'https://www.twitter.com/',
    linkedInURL: 'https://www.linkedin.com/',
    instagramURL: 'https://www.instagram.com/',
  },
  {
    id: 3,
    fullName: 'Charles Anderson',
    title: 'Board Member',
    pictureUrl: '/resources/images/avatars/4.jpg',
    twitterURL: 'https://www.twitter.com/',
    linkedInURL: 'https://www.linkedin.com/',
    instagramURL: 'https://www.instagram.com/',
  },
  {
    id: 4,
    fullName: 'Yvonne Palermo',
    title: 'Board Member',
    pictureUrl: '/resources/images/avatars/3.jpg',
    twitterURL: 'https://www.twitter.com/',
    linkedInURL: 'https://www.linkedin.com/',
    instagramURL: 'https://www.instagram.com/',
  },
]

let teamMembers = []
let leadership = []
let board = []

export function* FETCH_TEAM_MEMBERS({ resolve, reject }) {
  try {
    teamMembers = demoTeamMembers // (yield call(api.get, getEndPoints(['team', '0']))).data.members

    yield put({
      type: actions.FETCH_TEAM_MEMBERS_SUCCESS,
      payload: teamMembers,
      initialLoad: false,
    })

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_LEADERSHIP_MEMBERS({ resolve, reject }) {
  try {
    leadership = demoLeadership // (yield call(api.get, getEndPoints(['team', '0']))).data.members

    yield put({
      type: actions.FETCH_LEADERSHIP_MEMBERS_SUCCESS,
      payload: leadership,
      initialLoad: false,
    })

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_BOARD_MEMBERS({ resolve, reject }) {
  try {
    board = demoBoard // (yield call(api.get, getEndPoints(['team', '0']))).data.members

    yield put({
      type: actions.FETCH_BOARD_MEMBERS_SUCCESS,
      payload: board,
      initialLoad: false,
    })

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SEARCH_TEAM_MEMBERS({ keyword }) {
  const searchedTeamMembers = teamMembers.filter(
    teamMember =>
      teamMember.fullName && teamMember.fullName.toLowerCase().indexOf(keyword.toLowerCase()) > -1,
  )

  yield put({
    type: actions.SEARCH_TEAM_MEMBERS_SUCCESS,
    payload: searchedTeamMembers,
    initialLoad: true,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_TEAM_MEMBERS, FETCH_TEAM_MEMBERS),
    takeEvery(actions.FETCH_LEADERSHIP_MEMBERS, FETCH_LEADERSHIP_MEMBERS),
    takeEvery(actions.FETCH_BOARD_MEMBERS, FETCH_BOARD_MEMBERS),
    takeEvery(actions.SEARCH_TEAM_MEMBERS, SEARCH_TEAM_MEMBERS),
  ])
}
