import React, { Component } from 'react'
import { Form, Select, Row, Col } from 'antd'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import SelectField from '../FormSection/formFields/SelectField'
import InputFieldComponent from '../FormSection/component/InputFieldComponent'
import Formatters from '../../../utils/Formatters'

const { formatMoney, normalizeMoney } = Formatters

const { Option } = Select

const handleBlur = (event) => {
  event.preventDefault()
}

@connect(({ general }) => ({ general }))
class TransactionFields extends Component {

  handleOk = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'general/CHANGE_LEDGER_MODAL_STATUS',
      payload: {
        value: false,
      },
    })
  };

  handleCancel = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'general/CHANGE_LEDGER_MODAL_STATUS',
      payload: {
        value: false,
      },
    })
  };

  render() {

    return (
      <Row>
        <Col span={8}>
          <Form.Item>
            <div className="stretchSelectField">
              <SelectField
                name="enterAccount"
                placeholder="Enter Account"
                handleBlur={handleBlur}
              >
                <Option value="Account 1">Account 1</Option>
                <Option value="Account 2">Account 2</Option>
                <Option value="Account 3">Account 3</Option>
              </SelectField>
            </div>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item>
            <Field
              name="description"
              placeholder="Description"
              component={InputFieldComponent}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item>
            <Field
              name="amount"
              placeholder="Amount"
              component={InputFieldComponent}
              format={value => formatMoney(value)}
              normalize={value => normalizeMoney(value)}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>
            <div className="stretchSelectField">
              <SelectField
                name="tax"
                placeholder="Tax"
                handleBlur={handleBlur}
              >
                <Option value="0%">0%</Option>
                <Option value="5%">5%</Option>
                <Option value="10%">10%</Option>
                <Option value="15%">15%</Option>
              </SelectField>
            </div>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>
            <div className="stretchSelectField">
              <SelectField
                name="class"
                placeholder="Class"
                handleBlur={handleBlur}
              >
                <Option value="Class 1">Class 1</Option>
                <Option value="Class 2">Class 2</Option>
                <Option value="Class 3">Class 3</Option>
                <Option value="Class 4">Class 4</Option>
              </SelectField>
            </div>
          </Form.Item>
        </Col>
      </Row>
    )
  }
}


export default TransactionFields