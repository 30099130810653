import React, { Component, Fragment } from 'react'
import { Modal, Button, Form, Select, Row, Col } from 'antd'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import DatePickerFieldComponent from '../FormSection/component/DatePickerFieldComponent'
import SelectField from '../FormSection/formFields/SelectField'
import styles from '../../../pages/fund/investorInvitation/investorOnboardingStyle.module.scss'
import TransactionFields from './TransactionFields'

const { Option } = Select

const FORM_NAME = 'LedgerEntry'

const handleBlur = (event) => {
  event.preventDefault()
}

@connect(({ general }) => ({ general }))
class Index extends Component {

  handleOk = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'general/CHANGE_LEDGER_MODAL_STATUS',
      payload: {
        value: false,
      },
    })
  };

  handleCancel = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'general/CHANGE_LEDGER_MODAL_STATUS',
      payload: {
        value: false,
      },
    })
  };

  render() {
    const {
      general:{
        ledgerModalStatus
    }} = this.props

    return (
      <Modal
        title="New Ledger Entry"
        width={1000}
        visible={ledgerModalStatus}
        okText="Submit"
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Form layout="horizontal" colon={false}>
          <Row>
            <Col span={6}>
              <Form.Item>
                <Field
                  name="entryDate"
                  placeholder="Date"
                  component={DatePickerFieldComponent}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <div className="stretchSelectField">
                  <SelectField
                    name="payee"
                    placeholder="Payee"
                    handleBlur={handleBlur}
                  >
                    <Option value="Account 1">Account 1</Option>
                    <Option value="Account 2">Account 2</Option>
                    <Option value="Account 3">Account 3</Option>
                  </SelectField>
                </div>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <div className="stretchSelectField">
                  <SelectField
                    name="account"
                    placeholder="Enter Account"
                    handleBlur={handleBlur}
                  >
                    <Option value="Account 1">Account 1</Option>
                    <Option value="Account 2">Account 2</Option>
                    <Option value="Account 3">Account 3</Option>
                  </SelectField>
                </div>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <div className="stretchSelectField">
                  <SelectField
                    name="taxAmount"
                    placeholder="Select Tax"
                    handleBlur={handleBlur}
                  >
                    <Option value="0%">0%</Option>
                    <Option value="5%">5%</Option>
                    <Option value="10%">10%</Option>
                    <Option value="15%">15%</Option>
                  </SelectField>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <TransactionFields />

          <Form.List name="additionalTransactions">
            {(fields, { add, remove }) => {
              return (
                <Fragment>
                  {fields.map((field) => (
                    <div key={field.key}>
                      <TransactionFields />
                    </div>
                  ))}
                  <div style={{display: "flex"}}>
                    <Button
                      type="link"
                      size="large"
                      onClick={() => {
                        add()
                      }}
                    >
                      <h3 className="addButton">
                        <PlusCircleFilled className="plusIcon" /> Add Transaction Line
                      </h3>
                    </Button>
                    {fields.length >= 1 ? (
                      <Button
                        type="link"
                        size="large"
                        onClick={() => {
                          remove((fields.length-1))
                        }}
                      >
                        <h3 className={styles.removeButton}>
                          <MinusCircleFilled className={styles.minusIcon} /> Remove Transaction Line
                        </h3>
                      </Button>
                    ) : null }
                  </div>
                </Fragment>
              )
            }}
          </Form.List>


        </Form>
      </Modal>
    )
  }
}

const NewLedgerEntry = reduxForm({
  form: FORM_NAME,
})(Index)

const connectedForm = connect(
  (state) => ({ ioInvitationFormData: state.investoronboarding.ioInvitationFormData }),
  (dispatch) => ({ dispatch }),
)(NewLedgerEntry)

export default withRouter(connectedForm)
