import { takeEvery, all, call } from 'redux-saga/effects'

import actions from './actions'
import { publishingDraft, approvalNeeded, history } from './mockData/data'

export function* FETCH_PUBLISHING_DRAFT({ resolve, reject }) {
  try {
    yield call(resolve, publishingDraft)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_APPROVAL_NEEDED({ resolve, reject }) {
  try {
    yield call(resolve, approvalNeeded)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_HISTORY({ resolve, reject }) {
  try {
    yield call(resolve, history)
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PUBLISHING_DRAFT, FETCH_PUBLISHING_DRAFT),
    takeEvery(actions.FETCH_APPROVAL_NEEDED, FETCH_APPROVAL_NEEDED),
    takeEvery(actions.FETCH_HISTORY, FETCH_HISTORY),
  ])
}
